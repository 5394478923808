import { Trans } from '@lingui/macro'
import { Trace } from 'analytics'
import { ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { RowBetween, RowFixed } from 'components/Row'
import {
  LARGE_MEDIA_BREAKPOINT,
  MAX_WIDTH_MEDIA_BREAKPOINT,
  MEDIUM_MEDIA_BREAKPOINT,
} from 'components/Tokens/constants'
import { filterStringAtom } from 'components/Tokens/state'
import SearchBar from 'components/Tokens/TokenTable/SearchBar'
import TransactionConfirmationModal, { ConfirmationModalContent } from 'components/TransactionConfirmationModal'
import { useAllHarvest } from 'hooks/useHarvest'
import { useResetAtom } from 'jotai/utils'
import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Text } from 'rebass'
import { useIsTransactionPending } from 'state/transactions/hooks'
import styled, { useTheme } from 'styled-components'

import FarmTable from './FarmTable/FarmTable'

const ExploreContainer = styled.div`
  width: 100%;
  min-width: 320px;
  padding: 68px 12px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    padding-top: 48px;
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    padding-top: 20px;
  }
`

const HeaderContainer = styled.div`
  background-image: url('https://assets.spooky.fi/farm-v2.png');
  background-size: 100%;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  z-index: 0;
  position: relative;

  height: calc((100vw / 3) + 32px);
  aspect-ratio: 1200/553; /* Set your desired aspect ratio (height/width) */

  max-width: ${LARGE_MEDIA_BREAKPOINT};
  max-height: 390px;
  margin-bottom: -100px;
`

const TitleContainer = styled.div`
  margin-bottom: 32px;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  margin-left: auto;
  margin-right: auto;
  font-weight: 550;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const FiltersContainer = styled.div`
  display: flex;
  gap: 8px;
  height: 40px;

  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    order: 2;
  }
`
const SearchContainer = styled(FiltersContainer)`
  width: 100%;
  z-index: 2;

  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    margin: 0px;
    order: 1;
  }
`
const FiltersWrapper = styled.div`
  display: flex;
  width: 880px;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  margin: 0 auto;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.neutral3};
  flex-direction: row;
  justify-content: space-between;

  @media only screen and (max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT}) {
    width: 100%;
  }

  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    gap: 8px;
  }
`

const FarmsV3 = () => {
  const resetFilterString = useResetAtom(filterStringAtom)
  const { onReward } = useAllHarvest()
  const [txHash, setTxHash] = useState<string | undefined>()
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const theme = useTheme()
  const isClaimAllPending = useIsTransactionPending(txHash ?? undefined)

  const pendingText = <Trans>Claim All Farms</Trans>

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setTxHash(undefined)
  }, [txHash])

  const onClaimAll = useCallback(async () => {
    try {
      setAttemptingTxn(true)
      const response = await onReward()
      setTxHash(response.hash)
    } finally {
      setAttemptingTxn(false)
    }
  }, [onReward])

  function modalHeader() {
    return (
      <AutoColumn gap="sm" style={{ padding: '16px' }}>
        <RowBetween align="flex-end">
          <Text fontSize={16} fontWeight={535}>
            <Trans>Proceeding will claim all rewards from active farming</Trans>
          </Text>
          <RowFixed></RowFixed>
        </RowBetween>
        <ButtonPrimary mt="16px" onClick={onClaimAll} padding="10px">
          <Trans>Claim All</Trans>
        </ButtonPrimary>
      </AutoColumn>
    )
  }

  const location = useLocation()

  useEffect(() => {
    resetFilterString()
  }, [location, resetFilterString])

  return (
    <Trace page="v3-farm-page" shouldLogImpression>
      <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={attemptingTxn}
        hash={txHash ?? ''}
        reviewContent={() => (
          <ConfirmationModalContent
            title={<Trans>Claim All</Trans>}
            onDismiss={handleDismissConfirmation}
            topContent={modalHeader}
          />
        )}
        pendingText={pendingText}
      />
      <ExploreContainer>
        <HeaderContainer></HeaderContainer>
        <FiltersWrapper>
          <SearchContainer>
            <SearchBar />
          </SearchContainer>
        </FiltersWrapper>
        <FarmTable />
      </ExploreContainer>
    </Trace>
  )
}

export default FarmsV3
