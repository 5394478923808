import { createReducer } from '@reduxjs/toolkit'

import { cacheRPCTokenPrices, cacheV2GraphQLTokenPrices, PriceMap } from './actions'

export interface CacheState {
  readonly rpcTokenAddressToPriceMap: PriceMap
  readonly v2GraphQLTokenAddressToPriceMap: PriceMap
}

const initialState: CacheState = {
  rpcTokenAddressToPriceMap: {},
  v2GraphQLTokenAddressToPriceMap: {},
}

export default createReducer<CacheState>(initialState, (builder) =>
  builder
    .addCase(cacheRPCTokenPrices, (state, { payload }) => {
      return {
        ...state,
        rpcTokenAddressToPriceMap: payload,
      }
    })
    .addCase(cacheV2GraphQLTokenPrices, (state, { payload }) => {
      return {
        ...state,
        v2GraphQLTokenAddressToPriceMap: payload,
      }
    })
)
