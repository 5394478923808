import { InterfaceElementName, SwapEventName } from '@uniswap/analytics-events'
import { useWeb3React } from '@web3-react/core'
import { sendAnalyticsEvent } from 'analytics'
import { Gas } from 'components/Icons/Gas'
import { LoadingOpacityContainer } from 'components/Loader/styled'
import Row, { RowFixed } from 'components/Row'
import { MouseoverTooltip, TooltipSize } from 'components/Tooltip'
import { SUPPORTED_GAS_ESTIMATE_CHAIN_IDS } from 'constants/chains'
import { IHasTradeProperties } from 'state/routing/types'
import styled from 'styled-components'
import { ThemedText } from 'theme'
import { formatNumber, NumberType } from 'utils/formatNumbers'

import { GasBreakdownTooltip } from './GasBreakdownTooltip'

// eslint-disable-next-line import/no-unused-modules
export const StyledGasIcon = styled(Gas)`
  height: 16px;
  width: 16px;
  // We apply the following to all children of the SVG in order to override the default color
  & > * {
    fill: ${({ theme }) => theme.neutral2};
  }
`

export default function GasEstimateTooltip({ trade, loading }: { trade?: IHasTradeProperties; loading: boolean }) {
  const { chainId } = useWeb3React()

  if (!trade || !chainId || !SUPPORTED_GAS_ESTIMATE_CHAIN_IDS.includes(chainId)) {
    return null
  }

  return (
    <MouseoverTooltip
      size={TooltipSize.Small}
      text={<GasBreakdownTooltip trade={trade} />}
      onOpen={() => {
        sendAnalyticsEvent(SwapEventName.SWAP_AUTOROUTER_VISUALIZATION_EXPANDED, {
          element: InterfaceElementName.AUTOROUTER_VISUALIZATION_ROW,
        })
      }}
      placement="right"
    >
      <LoadingOpacityContainer $loading={loading}>
        <RowFixed gap="xs">
          <StyledGasIcon />
          <ThemedText.BodySmall color="neutral2">
            <Row gap="xs">
              <div>
                {formatNumber({
                  input: trade.totalGasUseEstimateUSD,
                  type: NumberType.FiatGasPrice,
                })}
              </div>
            </Row>
          </ThemedText.BodySmall>
        </RowFixed>
      </LoadingOpacityContainer>
    </MouseoverTooltip>
  )
}
