/* eslint-disable import/no-unused-modules */
import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { AppState } from '../reducer'
import { cacheRPCTokenPrices, cacheV2GraphQLTokenPrices, PriceMap } from './actions'

export function useCacheV3State(): AppState['cache'] {
  return useAppSelector((state) => state.cache)
}

export function useCachedRPCTokenPrices(): PriceMap {
  const { rpcTokenAddressToPriceMap } = useCacheV3State()
  return rpcTokenAddressToPriceMap
}

export function useCachedV2GraphQLTokenPrices(): PriceMap {
  const { v2GraphQLTokenAddressToPriceMap } = useCacheV3State()
  return v2GraphQLTokenAddressToPriceMap
}

export function useUSDPricesWithFallback(): PriceMap {
  const { v2GraphQLTokenAddressToPriceMap, rpcTokenAddressToPriceMap } = useCacheV3State()
  return useMemo(() => {
    const pricesWithFallback = { ...v2GraphQLTokenAddressToPriceMap }
    for (const key in rpcTokenAddressToPriceMap) {
      //TODO: fix the RPC map so it does not return inifite values
      if (!v2GraphQLTokenAddressToPriceMap[key] && Number.isFinite(rpcTokenAddressToPriceMap[key])) {
        pricesWithFallback[key] = rpcTokenAddressToPriceMap[key]
      }
    }
    return pricesWithFallback
  }, [v2GraphQLTokenAddressToPriceMap, rpcTokenAddressToPriceMap])
}

//TODO: invalidate cache on chain switch
export function useCacheActionHandlers(): {
  onRPCTokenPricesChange: (priceData: PriceMap) => void
  onV2GraphQLTokenPricesChange: (priceData: PriceMap) => void
} {
  const dispatch = useAppDispatch()

  const onRPCTokenPricesChange = useCallback(
    (priceData: PriceMap) => {
      dispatch(cacheRPCTokenPrices(priceData))
    },
    [dispatch]
  )

  const onV2GraphQLTokenPricesChange = useCallback(
    (priceData: PriceMap) => {
      dispatch(cacheV2GraphQLTokenPrices(priceData))
    },
    [dispatch]
  )

  return {
    onRPCTokenPricesChange,
    onV2GraphQLTokenPricesChange,
  }
}
