import { Trans } from '@lingui/macro'
import QuestionHelper from 'components/QuestionHelper'
import Row from 'components/Row'
import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { ThemedText } from 'theme'

const Option = styled(Row)<{ isActive: boolean }>`
  width: auto;
  cursor: pointer;
  padding: 6px 12px;
  text-align: center;
  gap: 4px;
  border-radius: 12px;
  background: ${({ isActive, theme }) => (isActive ? theme.surface3 : 'transparent')};
  pointer-events: ${({ isActive }) => isActive && 'none'};
`

const RoutingSettingsContainer = styled(Row)`
  justify-content: space-between;
`

const Switch = styled(Row)`
  width: auto;
  padding: 4px;
  border: 1px solid ${({ theme }) => theme.surface3};
  border-radius: 16px;
`

export default function RoutingSettings() {
  const navigate = useNavigate()
  const location = useLocation()

  const handleBestSwapClick = useCallback(() => {
    localStorage.setItem('routingOption', '/best-swap')
    navigate({
      pathname: '/best-swap',
    })
  }, [navigate])

  const handleSwapClick = useCallback(() => {
    localStorage.setItem('routingOption', '/swap')
    navigate({
      pathname: '/swap',
    })
  }, [navigate])

  return (
    <RoutingSettingsContainer>
      <Row width="auto">
        <ThemedText.BodySecondary>
          <Trans>Client Routing</Trans>
        </ThemedText.BodySecondary>
        <QuestionHelper
          text={<Trans>Client routing may be slower, but it could work when other routing options don't</Trans>}
        />
      </Row>
      <Switch>
        <Option onClick={handleBestSwapClick} isActive={location.pathname === '/best-swap'}>
          <ThemedText.BodyPrimary>
            <Trans>Off (fast)</Trans>
          </ThemedText.BodyPrimary>
        </Option>
        <Option onClick={handleSwapClick} isActive={location.pathname === '/swap'}>
          <ThemedText.BodyPrimary>
            <Trans>On</Trans>
          </ThemedText.BodyPrimary>
        </Option>
      </Switch>
    </RoutingSettingsContainer>
  )
}
