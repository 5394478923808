import { Trans } from '@lingui/macro'
import { BrowserEvent, InterfaceElementName, InterfacePageName, SharedEventName } from '@uniswap/analytics-events'
import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { Trace, TraceEvent } from 'analytics'
import { AboutFooter } from 'components/About/AboutFooter'
import Card, { CardType } from 'components/About/Card'
import { MAIN_CARDS, MORE_CARDS_BTT, MORE_CARDS_EON, MORE_CARDS_FTM } from 'components/About/constants'
import { DiscordIcon, GithubIcon, TwitterIcon } from 'components/About/Icons'
import { useAccountDrawer } from 'components/AccountDrawer'
import { BaseButton } from 'components/Button'
import { getChainInfo } from 'constants/chainInfo'
import { GITHUB_LINK, TELEGRAM_LINK } from 'constants/misc'
import { isFantom } from 'constants/tokens'
import { useDisableNFTRoutes } from 'hooks/useDisableNFTRoutes'
import Lottie from 'lottie-light-react'
import { TelegramIcon } from 'nft/components/icons'
import { useEffect, useMemo, useRef, useState } from 'react'
import { ArrowDownCircle } from 'react-feather'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { Link as NativeLink } from 'react-router-dom'
import { useAppSelector } from 'state/hooks'
import styled, { css } from 'styled-components'
import { BREAKPOINTS, ThemedText } from 'theme'
import { useIsDarkMode } from 'theme/components/ThemeToggle'
import { TRANSITION_DURATIONS } from 'theme/styles'
import { Z_INDEX } from 'theme/zIndex'

const PageContainer = styled.div`
  position: absolute;
  top: 0;
  padding: ${({ theme }) => theme.navHeight}px 0px 0px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-behavior: smooth;
  overflow: hidden;
  background-color: #162545;
`

const ContentContainer = styled.div<{ isDarkMode: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 100px;
  width: 100%;
  height: 100%;
  z-index: ${Z_INDEX.under_dropdown};
  transition: ${({ theme }) => `${theme.transition.duration.medium} ${theme.transition.timing.ease} opacity`};

  pointer-events: none;
  * {
    pointer-events: auto;
  }
  background-color: transparent;

  @media screen and (max-width: 1200px) {
    margin-top: 100px;
  }
`

const TitleText = styled.h1<{ isDarkMode: boolean }>`
  color: white;
  font-size: calc(26px + 2vw - 1vh);
  line-height: 44px;
  font-weight: 700;
  text-align: center;

  text-shadow: #02023b 2px 1px 7px;
`

const HeaderText = styled.div`
  font-size: 40px;
  line-height: 64px;
  font-weight: 700;
  @media screen and (max-width: 576px) {
    font-size: 20px;
  }
`

const LandingButton = styled(BaseButton)`
  padding: 16px 0px;
  font-size: calc(16px + 1.2vw - 1vh);
  border-radius: 24px;
`

const ButtonCTA = styled(LandingButton)`
  background-color: ${({ theme }) => theme.accent1};
  backdrop-filter: blur(8px);
  border: none;
  width: fit-content;
  min-width: 100px;
  color: ${({ theme }) => theme.white};
  transition: ${({ theme }) => `all ${theme.transition.duration.medium} ${theme.transition.timing.ease}`};
  &:hover {
    box-shadow: 0px 0px 16px 0px #5b1a75;
  }
`

const ButtonHome = styled(LandingButton)`
  background-color: ${({ theme }) => theme.accent1};
  backdrop-filter: blur(8px);
  border: none;
  min-width: 100px;
  padding: 0;
  color: ${({ theme }) => theme.white};
  transition: ${({ theme }) => `all ${theme.transition.duration.medium} ${theme.transition.timing.ease}`};
  &:hover {
    box-shadow: 0px 0px 16px 0px #5b1a75;
  }
`

const ButtonHomeText = styled.p`
  margin: 0px;
  font-size: 14px;
  width: fit-content;
  white-space: nowrap;
  color: black;
  // @media screen and (min-width: ${BREAKPOINTS.sm}px) {
  //   font-size: 20px;
  // }
`

const ButtonCTAText = styled.p`
  margin: 0px;
  font-size: 16px;
  width: fit-content;
  font-weight: 600;
  white-space: nowrap;
  @media screen and (min-width: ${BREAKPOINTS.sm}px) {
    font-size: 20px;
  }
`

const ActionsContainer = styled.span`
  max-width: 300px;
  width: 20vw;
  pointer-events: auto;
`

const LearnMoreContainer = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.neutral1};
  cursor: pointer;
  font-size: 20px;
  font-weight: 535;
  margin: 10px 0 0;
  display: flex;
  visibility: hidden;
  pointer-events: auto;
  @media screen and (min-width: ${BREAKPOINTS.sm}px) {
    visibility: visible;
  }

  transition: ${({ theme }) => `${theme.transition.duration.medium} ${theme.transition.timing.ease} opacity`};

  &:hover {
    opacity: 0.6;
  }
`

const LearnMoreArrow = styled(ArrowDownCircle)`
  margin-left: 14px;
  size: 20px;
`

const AboutContentContainer = styled.div<{ isDarkMode: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vh
  padding: 0 24px 5rem;
  width: 100%;
  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    padding: 0 96px 5rem;
  }
`

const CardGrid = styled.div<{ cols: number }>`
  display: grid;
  gap: 12px;
  width: 100%;
  padding: 24px 0 0;
  max-width: 1440px;
  scroll-margin: ${({ theme }) => `${theme.navHeight}px 0 0`};

  grid-template-columns: 1fr;
  @media screen and (min-width: ${BREAKPOINTS.sm}px) {
    // At this screen size, we show up to 2 columns.
    grid-template-columns: ${({ cols }) =>
      Array.from(Array(cols === 2 ? 2 : 1))
        .map(() => '1fr')
        .join(' ')};
    gap: 32px;
  }

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    // at this screen size, always show the max number of columns
    grid-template-columns: ${({ cols }) =>
      Array.from(Array(cols))
        .map(() => '1fr')
        .join(' ')};
    gap: 32px;
  }
`

const LinkCss = css`
  text-decoration: none;
  /* max-width: 480px; */
  width: 100%;
`

const Link = styled(NativeLink)`
  ${LinkCss}
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: end;
`

const LottieContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-top: 100px;
  margin-left: 750px;
  min-height: 70vh;

  @media screen and (max-width: 1920px) {
    margin-left: 650px;
  }

  @media screen and (max-width: 1600px) {
    margin-left: 350px;
  }

  @media screen and (max-width: 1200px) {
    margin-left: 250px;
    min-height: 0vh;
  }

  @media screen and (max-width: 992px) {
    margin-left: 20px;
    padding-top: 10px;
  }

  @media screen and (max-width: 768px) {
    margin-left: 10px; /* Center align for very small screens */
  }

  @media screen and (max-width: 576px) {
    margin-left: 10px; /* Center align for very small screens */
    margin-right: 10px;
  }
`

const FlexStartColumn = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${({ width }) => width || 'unset'};
  margin-left: 20px;
`

const textItems = ['Swap', 'Farm', 'Vote', 'Stake', 'Bridge', 'Mint']

const GrimContainer1920 = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.navHeight}px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  max-width: 1920px;
  width: 100%;

  pointer-events: none;

  @media screen and (max-width: 1200px) {
    position: relative;
  }

  @media screen and (max-width: 60px) {
    margin: 0;
  }
`

const SubTag = styled.div`
  padding-right: 8px;
  max-width: 600px;
`

const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px 0 0 0;
  padding: 10px;
  border: 2px solid white;
  border-radius: 24px;
`

const SocialLink = styled.a`
  display: flex;
`
async function fetchAnimationData(url: string) {
  const response = await fetch(url)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  return response.json()
}

export default function Landing() {
  const isDarkMode = useIsDarkMode()
  const cardsRef = useRef<HTMLDivElement>(null)
  const selectedWallet = useAppSelector((state) => state.user.selectedWallet)
  const shouldDisableNFTRoutes = useDisableNFTRoutes()
  const { chainId } = useWeb3React()
  const isBtt = chainId === ChainId.BIT_TORRENT_MAINNET
  const isEon = chainId === ChainId.EON
  const cards = useMemo(
    () => MAIN_CARDS.filter((card) => !(shouldDisableNFTRoutes && card.to.startsWith('/nft'))),
    [shouldDisableNFTRoutes]
  )

  const [accountDrawerOpen] = useAccountDrawer()
  const navigate = useNavigate()
  useEffect(() => {
    if (accountDrawerOpen) {
      setTimeout(() => {
        navigate('/swap')
      }, TRANSITION_DURATIONS.fast)
    }
  }, [accountDrawerOpen, navigate])

  // const queryParams = parse(useLocation().search, { ignoreQueryPrefix: true })
  // if (selectedWallet && !queryParams.intro) {
  //   return <RedirectPathToSwapOnly />
  // }

  //TODO: RESIZE ELEMENTS AND CHANGE WEBSITE TO MOBILE UNDER 768PX

  const [currentIndex, setCurrentIndex] = useState(0)
  const [fade, setFade] = useState(true)

  const { data: animationData, isLoading: loadingAnimationData } = useQuery(
    ['fetchAnimationData', 'https://cdn.lottielab.com/l/6DCE8RCuzdb3wN.json'],
    () => fetchAnimationData('https://cdn.lottielab.com/l/6DCE8RCuzdb3wN.json')
  )

  const { data: animationDataSmallScreen, isLoading: loadingAnimationDataSmallScreen } = useQuery(
    ['fetchAnimationDataSmallScreen', 'https://cdn.lottielab.com/l/33CN6j4ADjXEtQ.json'],
    () => fetchAnimationData('https://cdn.lottielab.com/l/33CN6j4ADjXEtQ.json')
  )

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFade(false)
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % textItems.length)
        setFade(true)
      }, 600)
    }, 2000)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <Trace page={InterfacePageName.LANDING_PAGE} shouldLogImpression>
      <PageContainer data-testid="landing-page">
        <HeaderContainer>
          <LottieContainer>
            <FlexStartColumn>
              <Trans>SpookySwap</Trans>
              <HeaderText>
                The Portal to DeFi:&nbsp;&nbsp;
                <div
                  style={{ display: 'inline-block', width: '148px', transition: 'opacity 0.6s', opacity: fade ? 1 : 0 }}
                >
                  <HeaderText>{textItems[currentIndex]}</HeaderText>
                </div>
              </HeaderText>
              <SubTag>
                <ThemedText.BodySecondary>
                  All in one decentralized exchange for leveraging diversified funds across ecosystems, with the speed
                  of
                  {' ' + getChainInfo(chainId)?.label}
                </ThemedText.BodySecondary>
              </SubTag>
              <SocialLinks>
                <SocialLink href="https://discord.com/invite/weXbvPAH4Q" target="_blank" rel="noopener noreferrer">
                  <DiscordIcon size={20} />
                </SocialLink>
                <TraceEvent
                  events={[BrowserEvent.onClick]}
                  name={SharedEventName.ELEMENT_CLICKED}
                  element={InterfaceElementName.TWITTER_LINK}
                >
                  <SocialLink href="https://twitter.com/spookyswap" target="_blank" rel="noopener noreferrer">
                    <TwitterIcon size={20} />
                  </SocialLink>
                </TraceEvent>
                <SocialLink href={GITHUB_LINK} target="_blank" rel="noopener noreferrer">
                  <GithubIcon size={20} />
                </SocialLink>
                <SocialLink href={TELEGRAM_LINK} target="_blank" rel="noopener noreferrer">
                  <TelegramIcon height={24} width={24} />
                </SocialLink>
                {chainId && isFantom(chainId) ? (
                  <ButtonHome
                    as={Link}
                    to="/swap?inputCurrency=ETH&outputCurrency=0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE"
                  >
                    <ButtonHomeText>
                      <Trans>Get BOO</Trans>
                    </ButtonHomeText>
                  </ButtonHome>
                ) : (
                  <ButtonHome as={Link} to="/swap">
                    <ButtonHomeText>
                      <Trans>Swap</Trans>
                    </ButtonHomeText>
                  </ButtonHome>
                )}
              </SocialLinks>
            </FlexStartColumn>
          </LottieContainer>
          <GrimContainer1920>
            {loadingAnimationData || loadingAnimationDataSmallScreen ? (
              <div>Loading...</div>
            ) : window.innerWidth > 660 ? (
              <Lottie animationData={animationData} loop={true} />
            ) : (
              <Lottie animationData={animationDataSmallScreen} loop={true} />
            )}
          </GrimContainer1920>
        </HeaderContainer>
        <ContentContainer isDarkMode={isDarkMode}>
          <TitleText isDarkMode={isDarkMode}>
            <Trans>SpookySwap V3</Trans>
          </TitleText>

          <ActionsContainer>
            <TraceEvent
              events={[BrowserEvent.onClick]}
              name={SharedEventName.ELEMENT_CLICKED}
              element={InterfaceElementName.CONTINUE_BUTTON}
            >
              <ButtonCTA as={Link} to="/swap">
                <ButtonCTAText>
                  <Trans>Get started</Trans>
                </ButtonCTAText>
              </ButtonCTA>
            </TraceEvent>
          </ActionsContainer>
          <LearnMoreContainer
            onClick={() => {
              cardsRef?.current?.scrollIntoView({ behavior: 'smooth' })
            }}
          >
            <Trans>Learn more</Trans>
            <LearnMoreArrow />
          </LearnMoreContainer>
        </ContentContainer>
        <AboutContentContainer isDarkMode={isDarkMode}>
          <CardGrid cols={cards.length} ref={cardsRef}>
            {cards.map(({ darkBackgroundImgSrc, lightBackgroundImgSrc, ...card }) => (
              <Card
                {...card}
                backgroundImgSrc={isDarkMode ? darkBackgroundImgSrc : lightBackgroundImgSrc}
                key={card.title}
              />
            ))}
          </CardGrid>
          <CardGrid cols={3}>
            {isEon
              ? MORE_CARDS_EON.map(({ darkIcon, lightIcon, ...card }) => (
                  <Card {...card} icon={isDarkMode ? darkIcon : lightIcon} key={card.title} type={CardType.Secondary} />
                ))
              : isBtt
              ? MORE_CARDS_BTT.map(({ darkIcon, lightIcon, ...card }) => (
                  <Card {...card} icon={isDarkMode ? darkIcon : lightIcon} key={card.title} type={CardType.Secondary} />
                ))
              : MORE_CARDS_FTM.map(({ darkIcon, lightIcon, ...card }) => (
                  <Card {...card} icon={isDarkMode ? darkIcon : lightIcon} key={card.title} type={CardType.Secondary} />
                ))}
          </CardGrid>
          <div
            id="spacer"
            style={{
              height: '100px',
              width: '100%',
              backgroundColor: 'transparent',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          ></div>
          <AboutFooter />
        </AboutContentContainer>
      </PageContainer>
    </Trace>
  )
}
