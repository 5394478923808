import { Trans } from '@lingui/macro'
import { InterfaceEventName } from '@uniswap/analytics-events'
import { ChainId, CurrencyAmount, Fraction, Token } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { sendAnalyticsEvent } from 'analytics'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import Earnings from 'components/Earnings'
import { ArrowChangeDown } from 'components/Icons/ArrowChangeDown'
import { ArrowChangeUp } from 'components/Icons/ArrowChangeUp'
import { Info } from 'components/Icons/Info'
import {
  LARGE_MEDIA_BREAKPOINT,
  MAX_WIDTH_MEDIA_BREAKPOINT,
  MEDIUM_MEDIA_BREAKPOINT,
  SMALL_MEDIA_BREAKPOINT,
} from 'components/Tokens/constants'
import { LoadingBubble } from 'components/Tokens/loading'
import { formatDelta } from 'components/Tokens/TokenDetails/Delta'
import { MouseoverTooltip } from 'components/Tooltip'
import { DAYS_IN_YEAR, DEFAULT_CHAIN_ID, LP_FEE_SHARE, SITE_NAME } from 'constants/misc'
import { getFarmDetailsURL } from 'graphql/data/util'
import { usePairAddresstoCurrency } from 'hooks/usePairAddresstoCurrency'
import { AverageDayTradeFeesMap } from 'hooks/v2/useGetLPTradeFees'
import { useAtomValue } from 'jotai/utils'
import JSBI from 'jsbi'
import { ForwardedRef, forwardRef, useMemo } from 'react'
import { CSSProperties, ReactNode } from 'react'
import React from 'react'
import { Link } from 'react-router-dom'
import { PriceMap } from 'state/cache/actions'
import { Farm } from 'state/farm/types'
import styled, { css, useTheme } from 'styled-components'
import { BREAKPOINTS, ClickableStyle } from 'theme'
import { ToDecimalsExpanded } from 'utils/currency'
import { formatUSDPrice } from 'utils/formatNumbers'

import {
  FarmSortMethod,
  filterStringAtom,
  filterTimeAtom,
  sortAscendingAtom,
  sortMethodAtom,
  useSetSortMethod,
} from '../state'

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const StyledTokenRow = styled.div<{
  first?: boolean
  last?: boolean
  $loading?: boolean
}>`
  display: grid;
  font-size: 16px;
  grid-template-columns: 7fr 4fr 4fr 4fr 4fr 1fr;
  line-height: 24px;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  min-width: 390px;
  ${({ first, last }) => css`
    height: ${first || last ? '72px' : '72px'};
    padding-top: ${first ? '8px' : '0px'};
    padding-bottom: ${last ? '8px' : '0px'};
  `}
  padding-left: 1.5em;
  padding-right: 12px;
  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => css`background-color ${duration.medium} ${timing.ease}`};
  width: 100%;
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  &:hover {
    ${({ $loading, theme }) =>
      !$loading &&
      css`
        background-color: ${theme.deprecated_hoverDefault};
      `}
    ${({ last }) =>
      last &&
      css`
        border-radius: 0px 0px 8px 8px;
      `}
  }

  @media only screen and (max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT}) {
    grid-template-columns: 6.5fr 4.5fr 4.5fr 4.5fr 4.5fr 1.7fr;
  }

  @media only screen and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    grid-template-columns: 7.5fr 4.5fr 4.5fr 4.5fr 1.7fr;
  }

  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    grid-template-columns: 10fr 5fr 5fr 1.2fr;
  }

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    grid-template-columns: 2fr 3fr;
    min-width: unset;
    border-bottom: 0.5px solid ${({ theme }) => theme.surface2};

    :last-of-type {
      border-bottom: none;
    }
  }
`

const ClickableContent = styled.div<{ gap?: number }>`
  display: flex;
  ${({ gap }) => gap && `gap: ${gap}px`};
  text-decoration: none;
  color: ${({ theme }) => theme.neutral1};
  align-items: center;
  cursor: pointer;
`
const ClickableName = styled(ClickableContent)`
  gap: 8px;
  max-width: 100%;
`
const StyledHeaderRow = styled(StyledTokenRow)`
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.neutral1};
  border-radius: 8px 8px 0px 0px;
  color: ${({ theme }) => theme.neutral2};
  font-size: 14px;
  height: 60px;
  line-height: 16px;
  padding-left: 1.5em;
  width: 100%;
  justify-content: center;

  &:hover {
    background-color: transparent;
  }

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    justify-content: space-between;
  }
`

// const ListNumberCell = styled(Cell)<{ header: boolean }>`
//   color: ${({ theme }) => theme.neutral2};
//   min-width: 32px;
//   font-size: 14px;

//   @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
//     display: none;
//   }
// `
const DataCell = styled(Cell)<{ sortable: boolean }>`
  justify-content: flex-end;
  min-width: 80px;
  user-select: ${({ sortable }) => (sortable ? 'none' : 'unset')};
  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => css`background-color ${duration.medium} ${timing.ease}`};
`
const TvlCell = styled(DataCell)`
  padding-right: 8px;
  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    display: none;
  }
`
const NameCell = styled(Cell)`
  justify-content: flex-start;
  padding: 0px 8px;
  min-width: 240px;
  gap: 8px;

  @media only screen and (max-width: ${BREAKPOINTS.xs}px) {
    min-width: 200px;
  }
`
const PriceCell = styled(DataCell)`
  padding-right: 8px;
`
const PercentChangeCell = styled(DataCell)`
  padding-right: 8px;
`
const PercentChangeInfoCell = styled(Cell)`
  display: none;

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    display: flex;
    gap: 3px;
    justify-content: flex-end;
    color: ${({ theme }) => theme.neutral2};
    font-size: 12px;
    line-height: 16px;
  }
`
const PriceInfoCell = styled(Cell)`
  justify-content: flex-end;
  flex: 1;

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    flex-direction: column;
    align-items: flex-end;
  }
`

const HeaderCellWrapper = styled.span<{ onClick?: () => void }>`
  align-items: center;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'unset')};
  display: flex;
  gap: 4px;
  justify-content: flex-end;
  width: 100%;

  &:hover {
    ${ClickableStyle}
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
`
const TokenInfoCell = styled(Cell)`
  gap: 8px;
  line-height: 24px;
  font-size: 16px;
  max-width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    justify-content: flex-start;
    flex-direction: column;
    gap: 0px;
    width: max-content;
    font-weight: 535;
  }
`
const TokenName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`
const TokenSymbol = styled(Cell)`
  color: ${({ theme }) => theme.neutral1};
  text-transform: uppercase;

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    font-size: 12px;
    height: 16px;
    justify-content: flex-start;
    width: 100%;
  }
`
const DepositedCell = styled(DataCell)`
  padding-right: 8px;
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    display: none;
  }
`
const RewardsCell = styled(DataCell)`
  padding-right: 8px;
  @media only screen and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    display: none;
  }
`
const SmallLoadingBubble = styled(LoadingBubble)`
  width: 25%;
`
const MediumLoadingBubble = styled(LoadingBubble)`
  width: 65%;
`
const IconLoadingBubble = styled(LoadingBubble)`
  border-radius: 50%;
  width: 24px;
`

const InfoIconContainer = styled.div`
  width: 16px;
  margin-left: 2px;
  display: flex;
  align-items: center;
  cursor: help;
`

const HEADER_DESCRIPTIONS: Record<FarmSortMethod, ReactNode | undefined> = {
  [FarmSortMethod.PRICE]: undefined,
  [FarmSortMethod.REWARDS_APY]: (
    <Trans>Annual Percentage Yield (APY) is a representaiton of the compounded yield which is received annually.</Trans>
  ),
  [FarmSortMethod.TOTAL_VALUE_LOCKED]: (
    <Trans>Total value locked (TVL) is the total amount of the asset locked within {SITE_NAME} Farms.</Trans>
  ),
  [FarmSortMethod.DEPOSITED]: (
    <Trans>Deposited is the amount of the asset you have deposited into the {SITE_NAME} v2 Farm.</Trans>
  ),
}

/* Get singular header cell for header row */
function HeaderCell({
  category,
}: {
  category: FarmSortMethod // TODO: change this to make it work for trans
}) {
  const theme = useTheme()
  const sortAscending = useAtomValue(sortAscendingAtom)
  const handleSortCategory = useSetSortMethod(category)
  const sortMethod = useAtomValue(sortMethodAtom)

  const description = HEADER_DESCRIPTIONS[category]

  return (
    <HeaderCellWrapper onClick={handleSortCategory}>
      {sortMethod === category && (
        <>
          {sortAscending ? (
            <ArrowChangeUp width={16} height={16} color={theme.neutral2} />
          ) : (
            <ArrowChangeDown width={16} height={16} color={theme.neutral2} />
          )}
        </>
      )}
      {category}
      {description && (
        <MouseoverTooltip text={description} placement="right">
          <InfoIconContainer>
            <Info width="16px" height="16px" />
          </InfoIconContainer>
        </MouseoverTooltip>
      )}
    </HeaderCellWrapper>
  )
}
/* Token Row: skeleton row component */
function FarmRow({
  header,
  // listNumber, //TODO: ADD listNumber when sortRank is implemented
  tokenInfo,
  apy,
  deposited,
  claimable,
  tvl,
  ...rest
}: {
  first?: boolean
  header: boolean
  //listNumber: ReactNode
  $loading?: boolean
  tvl: ReactNode
  apy: ReactNode
  deposited: ReactNode
  claimable: ReactNode
  tokenInfo: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowCells = (
    <>
      {/* <ListNumberCell header={header}>{listNumber}</ListNumberCell> */}
      <NameCell data-testid="name-cell">{tokenInfo}</NameCell>
      <PercentChangeCell data-testid="percent-change-cell" sortable={header}>
        {apy}
      </PercentChangeCell>
      <TvlCell data-testid="tvl-cell" sortable={header}>
        {tvl}
      </TvlCell>
      <DepositedCell data-testid="deposited-cell" sortable={header}>
        {deposited}
      </DepositedCell>
      <RewardsCell sortable={header}>{claimable}</RewardsCell>
    </>
  )
  if (header) return <StyledHeaderRow data-testid="header-row">{rowCells}</StyledHeaderRow>
  return <StyledTokenRow {...rest}>{rowCells}</StyledTokenRow>
}

/* Header Row: top header row component for table */
export function HeaderRow() {
  return (
    <FarmRow
      header={true}
      // listNumber="#"
      tokenInfo={<Trans>Farm Name</Trans>}
      //TODO:SHOW APY
      apy={<Trans>APR</Trans>}
      // apy={<HeaderCell category={FarmSortMethod.REWARDS_APY} />}
      tvl={<HeaderCell category={FarmSortMethod.TOTAL_VALUE_LOCKED} />}
      deposited={<HeaderCell category={FarmSortMethod.DEPOSITED} />}
      claimable={<Trans>Reward</Trans>}
    />
  )
}

/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <FarmRow
      header={false}
      // listNumber={<SmallLoadingBubble />}
      $loading
      tokenInfo={
        <>
          <IconLoadingBubble />
          <MediumLoadingBubble />
        </>
      }
      apy={<LoadingBubble />}
      tvl={<LoadingBubble />}
      deposited={<LoadingBubble />}
      claimable={<LoadingBubble />}
      {...props}
    />
  )
}

interface LoadedRowProps {
  farmListIndex: number
  farmListLength: number
  farm: NonNullable<Farm>
  sortRank: number
  priceMap: PriceMap
  averageDayTradeFeesMap: AverageDayTradeFeesMap
  inactive?: boolean
}

function ToUSD(priceMap: PriceMap, currencyAmount: CurrencyAmount<Token>) {
  //TODO: this should use big number math instead
  const currencyKey = currencyAmount.currency.address.toLowerCase()
  const tokenPrice = priceMap[currencyKey] ?? 0

  if (tokenPrice == 0) {
    console.log('could not find usd amount for pair ' + currencyKey)
  }
  return Number(currencyAmount.toSignificant()) * tokenPrice
}

function pairAmountsToUSD(
  priceMap: PriceMap,
  currencyAmount0: CurrencyAmount<Token>,
  currencyAmount1: CurrencyAmount<Token>
) {
  const usdAmount0 = ToUSD(priceMap, currencyAmount0)
  const usdAmount1 = ToUSD(priceMap, currencyAmount1)

  if (
    Number.isNaN(usdAmount0) ||
    Number.isNaN(usdAmount1) ||
    !Number.isFinite(usdAmount0) ||
    !Number.isFinite(usdAmount1)
  ) {
    return 0
  }

  return usdAmount0 + usdAmount1
}

//TODO: we currently don't support pair values from the RPC, which means that if we use the
// fallback we will end up returning 0 for usd amount.
function priceAmountFromLPToken(
  priceMap: PriceMap,
  pairAmount: CurrencyAmount<Token>,
  tokenAmount?: CurrencyAmount<Token>,
  quoteTokenAmount?: CurrencyAmount<Token>
) {
  let usdAmount = ToUSD(priceMap, pairAmount)
  if (!usdAmount && tokenAmount && quoteTokenAmount) {
    usdAmount = pairAmountsToUSD(priceMap, tokenAmount, quoteTokenAmount)
  }

  return usdAmount
}

const getFarmApr = (
  rewardPerYear: JSBI,
  rewardsPerYearDecimals: number,
  priceUsd: number,
  totalLiquidityUsd: number
) => {
  //convert to 1e18 so out to maintaing percision in relation to default decimals
  const pricePer = Math.round(priceUsd * 1e18)
  const totalLiquidityBI = Math.round(totalLiquidityUsd * 1e18)
  //new Fraction(pricePer, totalLiquidityBI), ONE
  const aprPerShare = new Fraction(pricePer, totalLiquidityBI)
  const apr = aprPerShare.multiply(rewardPerYear).divide(ToDecimalsExpanded(rewardsPerYearDecimals)).multiply(100)
  return Number(apr.toFixed(4))
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { farmListIndex, farmListLength, farm, sortRank, priceMap, averageDayTradeFeesMap } = props
  const filterString = useAtomValue(filterStringAtom)

  // const filterNetwork = validateUrlChainParam(useParams<{ chain?: string }>().chain?.toUpperCase())
  // const chainId = supportedChainIdFromGQLChain(filterNetwork)

  const { chainId } = useWeb3React()
  const chainIdOrDefault = chainId ?? DEFAULT_CHAIN_ID
  const timePeriod = useAtomValue(filterTimeAtom)

  // TODO: get the APY
  // const delta = farm.apy
  const delta = NaN
  const formattedDelta = formatDelta(delta)

  const exploreFarmSelectedEventProperties = {
    chain_id: chainId,
    farm_address: farm.lpAddress,
    farm_symbol: farm.lpSymbol,
    farm_list_index: farmListIndex,
    farm_list_length: farmListLength,
    time_frame: timePeriod,
    search_token_address_input: filterString,
  }

  const [currencyA, currencyB] = usePairAddresstoCurrency(farm.quoteToken.address, farm.token.address)

  // TODO: currency logo sizing mobile (32px) vs. desktop (24px)
  const earnings = farm.userData?.rewardTokenEarnings

  //TODO: we need to
  const lpStaked = farm.userData?.stakedBalance
    ? formatUSDPrice(priceAmountFromLPToken(priceMap, farm.userData?.stakedBalance))
    : 'loading...'

  const tvlNumber = farm?.lpBalanceInChef
    ? priceAmountFromLPToken(priceMap, farm.lpBalanceInChef, farm.tokenReserve, farm.quoteTokenReserve)
    : undefined

  const tvl = tvlNumber ? formatUSDPrice(tvlNumber) : 'loading...'

  const totalApr = useMemo(() => {
    if (!farm.rewardTokens) return

    const tradeData = averageDayTradeFeesMap[farm.lpAddress.toLowerCase()]

    const tradefeeAPR =
      tradeData && tradeData.averageVolumeUSD && tradeData.reserveUSD
        ? (LP_FEE_SHARE * DAYS_IN_YEAR * 100 * tradeData.averageVolumeUSD) / tradeData.reserveUSD
        : 0

    const rewardapys = farm.rewardTokens
      .map((rewardsData) => {
        const token = new Token(
          chainIdOrDefault,
          rewardsData.token.address,
          rewardsData.token.decimals,
          rewardsData.token.symbol
        )

        // Rewards per year is in raw form we need to divide it by its decimals

        if (!tvlNumber) {
          return 0
        }
        // To Decimals Expanded is the equavalent of 1 in the base token
        const amount = CurrencyAmount.fromRawAmount(token, ToDecimalsExpanded(token.decimals))
        const pricePerToken = ToUSD(priceMap, amount)
        const apr = getFarmApr(rewardsData.rewardPerYear, token.decimals, pricePerToken, tvlNumber)
        return apr
      })
      .reduce((a, e) => a + e, 0)

    return tradefeeAPR + rewardapys
  }, [averageDayTradeFeesMap, chainId, farm.lpAddress, farm.rewardTokens, priceMap, tvlNumber])

  const totalAprFormatted = totalApr !== undefined ? formatUSDPrice(totalApr) : 'loading...'

  return (
    <div ref={ref} data-testid={`token-table-row-${farm.lpAddress}`}>
      <StyledLink
        to={getFarmDetailsURL(farm)}
        onClick={() =>
          sendAnalyticsEvent(InterfaceEventName.EXPLORE_TOKEN_ROW_CLICKED, exploreFarmSelectedEventProperties)
        }
      >
        <FarmRow
          header={false}
          //TODO: CHANGE farm.pid to {sortRank} when sortRank is implemented
          // listNumber={sortRank}
          tokenInfo={
            <ClickableName>
              <DoubleCurrencyLogo currency0={currencyA} currency1={currencyB} />
              <TokenInfoCell>
                {/*<TokenName data-cy="token-name">{farm.name}</TokenName*/}
                <TokenSymbol>{farm.lpSymbol}</TokenSymbol>
              </TokenInfoCell>
            </ClickableName>
          }
          apy={
            //TODO: resolve APY
            <ClickableContent gap={3}>
              {totalAprFormatted.replace(/\$/g, '')}%
              {
                //<RewardPerDay rewardTokens={farm?.rewardTokens} />
              }
            </ClickableContent>
          }
          tvl={
            <ClickableContent>
              {
                `${tvl}`

                // formatNumber({
                //   input: farm.lpTotalSupply,
                //   type: NumberType.FiatTokenStats,
                // })
              }
            </ClickableContent>
          }
          deposited={<ClickableContent>{lpStaked}</ClickableContent>}
          claimable={earnings && chainId !== ChainId.BIT_TORRENT_MAINNET ? <Earnings earnings={earnings} /> : <></>}
          first={farmListIndex === 0}
          last={farmListIndex === farmListLength - 1}
        />
      </StyledLink>
    </div>
  )
})

LoadedRow.displayName = 'LoadedRow'
