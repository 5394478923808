// @ts-nocheck
import { Fraction } from '@uniswap/sdk-core'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { useCurrency } from 'hooks/Tokens'
import { EarningAmounts } from 'state/farm/types'
import styled, { useTheme } from 'styled-components'
import { ExtendedCurrency } from 'types/ExtendedCurrency'
import { ToDecimalsExpandedOrDefault } from 'utils/currency'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.15);
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding: 4px;
  background: ${({ theme }) => theme.cardBackground};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(150, 150, 150, 0.1);
  transition: background 0.3s ease;

  &:hover {
    background: ${({ theme }) => theme.cardHoverBackground};
  }
`

const EarningsText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.success};
  font-weight: bold;
  font-size: 1.em;
`

const Earnings = ({ earnings }) => {
  const theme = useTheme()
  
  const defaultEarningToken = earnings[0]
  const bonusEarningToken = earnings[1]

  const defaultCurrency = useCurrency(defaultEarningToken.address)
  const defaultDecimals = ToDecimalsExpandedOrDefault(defaultCurrency?.tokenInfo?.decimals)
  const normalizedDefaultEarningsDisplay = new Fraction(defaultEarningToken.earnings, defaultDecimals).toFixed(2)

  const bonusCurrency = useCurrency(bonusEarningToken?.address)
  const bonusDecimals = ToDecimalsExpandedOrDefault(bonusCurrency?.tokenInfo?.decimals)
  const normalizedBonusEarningsDisplay = bonusEarningToken
    ? new Fraction(bonusEarningToken.earnings, bonusDecimals).toFixed(2)
    : '0'

  return earnings.length === 1 ? (
    <Container>
      <Row>
        <EarningsText>
          {normalizedDefaultEarningsDisplay}
          {defaultCurrency && <CurrencyLogo currency={defaultCurrency} size="24px" />} / day
        </EarningsText>
      </Row>
    </Container>
  ) : earnings.length === 2 ? (
    <Container>
      <Row>
        <EarningsText>
          {normalizedDefaultEarningsDisplay}
          {defaultCurrency && <CurrencyLogo currency={defaultCurrency} size="24px" />}
        </EarningsText>
      </Row>
      <Row>
        <EarningsText>
          {normalizedBonusEarningsDisplay}
          {bonusCurrency && <CurrencyLogo currency={bonusCurrency} size="24px" />}
        </EarningsText>
      </Row>
    </Container>
  ) : (
    <></>
  )
}

export default Earnings