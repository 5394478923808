import { Trans } from '@lingui/macro'
import { AutoColumn } from 'components/Column'
import Img from 'components/Img'
import { AutoRow, RowBetween } from 'components/Row'
import { useIsMobile } from 'nft/hooks'
import styled from 'styled-components'

import { ThemedText } from '../../theme'

const TitleRow = styled(RowBetween)`
  color: ${({ theme }) => theme.neutral2};
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  text-align: center;
`

const MenuItem = styled.a`
  flex: 1;
  min-width: 150px;
  justify-content: space-between;
  text-decoration: none;
  align-items: center;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  border-bottom: 2px solid ${({ theme }) => theme.neutral2};
  color: ${({ theme }) => theme.neutral2};
  white-space: nowrap;
  cursor: pointer;
  transition: color 0.3s, border-color 0.3s; /* Add transition for smooth color change */

  &:nth-child(1) {
    align-items: left;
    text-align: left;
    animation: waveAnimation 2s infinite linear; /* Apply wave animation to the first MenuItem */
  }

  &:nth-child(2) {
    animation: waveAnimation 2s infinite 0.25s linear; /* Apply wave animation to the second MenuItem */
  }

  &:nth-child(3) {
    align-items: right;
    text-align: right;
    animation: waveAnimation 2s infinite 0.5s linear; /* Apply wave animation to the third MenuItem */
  }

  &:hover {
    color: ${({ theme }) => theme.accent1}; /* Change text color on hover */
    border-color: ${({ theme }) => theme.accent1}; /* Change border color on hover */
    animation: none; /* Remove wave animation on hover */
  }

  &:hover ~ & {
    animation: none; /* Remove animation on hover for all MenuItems */
  }

  @keyframes waveAnimation {
    0% {
      color: ${({ theme }) => theme.neutral2}; /* Start color */
      border-color: ${({ theme }) => theme.neutral2}; /* Start border color */
    }
    50% {
      color: ${({ theme }) => theme.accent1}; /* Middle color */
      border-color: ${({ theme }) => theme.accent1}; /* Middle border color */
    }
    100% {
      color: ${({ theme }) => theme.neutral2}; /* End color */
      border-color: ${({ theme }) => theme.neutral2}; /* End border color */
    }
  }

  @media (max-width: 501px) {
    text-align: center !important; /* Important to override other alignments */
  }
`

const BridgeWrapper = styled.div`
  background: ${({ theme }) => theme.surface1};
  // min-width: 100%;
  width: 100%;
  min-width: 0;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.surface3};
  margin: 0;
  // max-width: 1080px;
  padding: 24px 24px 0 24px;
`

export default function Bridge() {
  const isMobile = useIsMobile()

  return (
    <>
      <AutoColumn gap="lg" justify="center">
        <AutoColumn gap="lg" style={{ width: '100%', marginTop: '100px' }}>
          <TitleRow padding="0">
            <ThemedText.LargeHeader>
              <Trans> Bridge and Cross-Chain Swap </Trans>
            </ThemedText.LargeHeader>
          </TitleRow>
          <TitleRow padding="0">
            <ThemedText.DeprecatedMediumHeader>
              <Trans> To ensure your safety, you will be linked to visit the bridge website directly </Trans>
            </ThemedText.DeprecatedMediumHeader>
          </TitleRow>
        </AutoColumn>
        <AutoRow style={{ width: '100%', justifyContent: 'center', flexWrap: 'nowrap' }}>
          {window.innerWidth > 965 && !isMobile && (
            <Img src="https://assets.spooky.fi/bridge/portal_left.png" width={227} />
          )}
          <BridgeWrapper>
            <AutoRow
              style={{
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '10px',
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              <MenuItem href="https://app.squidrouter.com/" target="_blank">
                <ThemedText.DeprecatedMediumHeader>
                  <Trans>Squid (AXL)</Trans>
                </ThemedText.DeprecatedMediumHeader>
              </MenuItem>
              <MenuItem href="https://stargate.finance/transfer" target="_blank">
                <ThemedText.DeprecatedMediumHeader>
                  <Trans>Stargate (LZ)</Trans>
                </ThemedText.DeprecatedMediumHeader>
              </MenuItem>
              <MenuItem href="https://portalbridge.com/" target="_blank">
                <ThemedText.DeprecatedMediumHeader>
                  <Trans>Wormhole</Trans>
                </ThemedText.DeprecatedMediumHeader>
              </MenuItem>
            </AutoRow>
          </BridgeWrapper>
          {window.innerWidth > 965 && !isMobile && (
            <Img src="https://assets.spooky.fi/bridge/portal_right.png" width={227} />
          )}
        </AutoRow>
      </AutoColumn>
    </>
  )
}
