import { Token } from '@uniswap/sdk-core'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { RowFixed } from 'components/Row'
import { CHAIN_IDS_TO_NAMES } from 'constants/chains'
import { isGqlSupportedChain } from 'graphql/data/util'
import { usePositionTokenURI } from 'hooks/usePositionTokenURI'
import { PropsWithChildren, useRef, useState } from 'react'
import { ExternalLink } from 'react-feather'
import styled, { keyframes } from 'styled-components'
import { StyledRouterLink, ThemedText } from 'theme'
import { PositionDetails } from 'types/position'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'

export function PositionMetadata({
  position,
  height = 400,
}: {
  position: PositionDetails | { tokenId: string }
  height: number
}) {
  const tokenMetadata = usePositionTokenURI(position.tokenId)
  if (!tokenMetadata.loading && tokenMetadata.valid && tokenMetadata.result) {
    return (
      <div>
        {' '}
        <NFT image={tokenMetadata.result.image} height={height} />
      </div>
    )
  }

  // rest of the component logic
  return <NFT image="" height={height} />
}

// Define a keyframes animation for blinking
const blink = keyframes`
  0% {
    border-color: #eee;
  }
  50% {
    border-color: #ddd;
  }
  100% {
    border-color: #eee;
  }
`

// Style a div to use the blink animation
const BlinkingSkeleton = styled.div`
  animation: ${blink} 1s linear infinite;
  border: 1px solid #eee;
  background-color: transparent;
  border-radius: 4px;
  width: 60px;
  height: 100px;
  opacity: 0.5;
`

const pulse = keyframes`
  0% { background-color: #eee; }
  50% { background-color: #ddd; }
  100% { background-color: #eee; }
`

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  max-width: 600px;
  background-color: #ccc;
  border-radius: 4px;
  animation: ${pulse} 1s linear infinite;
`

const LoadingIcon = styled.svg`
  width: 10px;
  height: 10px;
  fill: #aaa;
`

const LoadingText = styled.span`
  display: none;
`

function NFT({ image, height: targetHeight }: { image: string; height: number }) {
  const [animate, setAnimate] = useState(false)

  const canvasRef = useRef<HTMLCanvasElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)

  return (
    <NFTGrid
      onMouseEnter={() => {
        setAnimate(true)
      }}
      onMouseLeave={() => {
        // snapshot the current frame so the transition to the canvas is smooth
        if (imageRef.current && canvasRef.current) {
          getSnapshot(imageRef.current, canvasRef.current, targetHeight)
        }
        setAnimate(false)
      }}
    >
      <NFTImage
        ref={imageRef}
        src={image}
        // hidden={!animate}
        onLoad={() => {
          // // snapshot for the canvas
          // if (imageRef.current && canvasRef.current) {
          //   getSnapshot(imageRef.current, canvasRef.current, targetHeight)
          // }
        }}
      />
    </NFTGrid>
  )
}

// snapshots a src img into a canvas
function getSnapshot(src: HTMLImageElement, canvas: HTMLCanvasElement, targetHeight: number) {
  const context = canvas.getContext('2d')

  if (context) {
    let { width, height } = src

    // src may be hidden and not have the target dimensions
    const ratio = width / height
    height = targetHeight
    width = Math.round(ratio * targetHeight)

    // Ensure crispness at high DPIs
    canvas.width = width * devicePixelRatio
    canvas.height = height * devicePixelRatio
    canvas.style.width = width + 'px'
    canvas.style.height = height + 'px'
    context.scale(devicePixelRatio, devicePixelRatio)

    context.clearRect(0, 0, width, height)
    context.drawImage(src, 0, 0, width, height)
  }
}

const ExternalTokenLink = ({ children, chainId, address }: PropsWithChildren<{ chainId: number; address: string }>) => {
  return <ExternalLink href={getExplorerLink(chainId, address, ExplorerDataType.TOKEN)}>{children}</ExternalLink>
}

const TokenLink = ({
  children,
  chainId,
  address,
}: PropsWithChildren<{ chainId: keyof typeof CHAIN_IDS_TO_NAMES; address: string }>) => {
  const chainName = CHAIN_IDS_TO_NAMES[chainId]
  return <StyledRouterLink to={`/tokens/${chainName}/${address}`}>{children}</StyledRouterLink>
}

export function LinkedCurrency({ chainId, currency }: { chainId?: number; currency?: Token }) {
  const address = currency?.address
  if (!chainId || !currency) {
    return null
  }

  if (address) {
    const Link = isGqlSupportedChain(chainId) ? TokenLink : ExternalTokenLink
    return (
      <Link chainId={chainId} address={address}>
        <RowFixed>
          <CurrencyLogo currency={currency} size="20px" style={{ marginRight: '0.5rem' }} />
          <ThemedText.DeprecatedMain>{currency?.symbol} ↗</ThemedText.DeprecatedMain>
        </RowFixed>
      </Link>
    )
  }

  return (
    <RowFixed>
      <CurrencyLogo currency={currency} size="20px" style={{ marginRight: '0.5rem' }} />
      <ThemedText.DeprecatedMain>{currency?.symbol}</ThemedText.DeprecatedMain>
    </RowFixed>
  )
}

const NFTGrid = styled.div`
  display: grid;
  grid-template: 'overlap';
  min-height: 50px;
  margin-right: 15px;
`

const NFTCanvas = styled.canvas`
  grid-area: overlap;
`

const NFTImage = styled.img`
  grid-area: overlap;
  height: 90px;
  /* Ensures SVG appears on top of canvas. */
  z-index: 1;

  &:hover {
    filter: brightness(1.5);
  }
`
const NFTCanvas2 = styled.div`
  position: relative;
  bottom: 27px;
  left: 24px;
  width: 15px;
  height: 15px;
  z-index: 2;
  color: green;

  &:hover {
    filter: brightness(1.5);
    color: #90ee90;
  }
`
