import { Trans } from '@lingui/macro'
import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useAccountDrawer } from 'components/AccountDrawer'
import { BridgeIcon } from 'components/Icons/Bridge'
import { ConvertIcon } from 'components/Icons/Convert'
import { PoolsIcon } from 'components/Icons/Pools'
import { StakeBOOIcon } from 'components/Icons/StakeBOO'
import { SwapIcon } from 'components/Icons/Swap'
import Web3Status from 'components/Web3Status'
import { BestSwapNewChains } from 'constants/chains'
import { useDisableNFTRoutes } from 'hooks/useDisableNFTRoutes'
import { useIsNftPage } from 'hooks/useIsNftPage'
import { useIsPoolsPage } from 'hooks/useIsPoolsPage'
import { Box } from 'nft/components/Box'
import { Row } from 'nft/components/Flex'
import { SpookyIcon, SpookyV2Icon } from 'nft/components/icons'
import { useProfilePageState } from 'nft/hooks'
import { ProfilePageStateType } from 'nft/types'
import { ReactNode, useCallback } from 'react'
import { NavLink, NavLinkProps, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { BREAKPOINTS } from 'theme'
import { isV3ConversionDisabled, isV3Disabled, isV3FarmDisabled } from 'utils/env'

import { useIsNavSearchInputVisible } from '../../nft/hooks/useIsNavSearchInputVisible'
import { Bag } from './Bag'
import Blur from './Blur'
import { ChainSelector } from './ChainSelector'
import FarmsDropdown from './FarmsDropdown'
import { MenuDropdown } from './MenuDropdown'
import { SearchBar } from './SearchBar'
import { SpookySwapText } from './SpookySwapText'
import * as styles from './style.css'

const Nav = styled.nav`
  padding: ${({ theme }) => `${theme.navVerticalPad}px 12px`};
  width: 100vw;
  height: ${({ theme }) => theme.navHeight}px;
  z-index: 2;
  background: #1e1d2d;
`
const ExternalMenuItem = styled.nav``

const SpookyIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;

  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    display: none;
  }
`
interface MenuItemProps {
  href: string
  id?: NavLinkProps['id']
  isActive?: boolean
  children: ReactNode
  dataTestId?: string
}

const MenuItem = ({ href, dataTestId, id, isActive, children }: MenuItemProps) => {
  const styleNav =
    id === 'swap-nav-id'
      ? styles.activeMenuSwap
      : id === 'liquidity-nav-id'
      ? styles.activeMenuLiquidity
      : id === 'farms-nav-id'
      ? styles.activeMenuFarms
      : id === 'stake-nav-id'
      ? styles.activeMenuStakeBOO
      : id === 'conversion-nav-id'
      ? styles.activeMenuConversion
      : styles.activeMenuItem

  return (
    <NavLink
      to={href}
      className={isActive ? styleNav : styles.menuItem}
      id={id}
      style={{ textDecoration: 'none' }}
      data-testid={dataTestId}
    >
      {children}
    </NavLink>
  )
}

const v3Disabled = isV3Disabled()
const isConvertDisabled = isV3ConversionDisabled()
const swapUrl = v3Disabled ? '/swap?use=v2' : '/swap'
const poolsUrl = v3Disabled ? '/pools/v2' : '/pools'
const farmsUrl = isV3FarmDisabled() ? 'https://spooky.fi/#/farms/v2' : '/farms/v3'
const bridgeUrl = '/bridge'
const conversionUrl = 'conversion'

export const PageTabs = () => {
  const { pathname } = useLocation()
  const isPoolActive = useIsPoolsPage()
  const isNftPage = useIsNftPage()
  const { chainId } = useWeb3React()
  const isFtm = chainId === ChainId.FANTOM
  const isBtt = chainId === ChainId.BIT_TORRENT_MAINNET
  const isEon = chainId === ChainId.EON
  const isBera = chainId === ChainId.BERA_TESTNET

  const isAlternateChain = isBtt || isEon || isBera

  const isNewBestSwapChain = chainId ? BestSwapNewChains.includes(chainId) : false

  const shouldDisableNFTRoutes = useDisableNFTRoutes()

  const subdomainSegmentMap: any = {
    [ChainId.BIT_TORRENT_MAINNET]: 'btt.',
    [ChainId.EON]: 'eon.',
  }
  const urlToV2 = `https://${chainId ? subdomainSegmentMap[chainId] ?? '' : ''}v2.spooky.fi`
  const urlToBridge = isFtm ? 'bridge' : isBtt ? 'https://app.bt.io/bridge' : ''

  const handleLinkClickBridge = () => {
    const externalUrl = urlToBridge
    window.open(externalUrl, '_blank')
  }

  const handleLinkClickStake = () => {
    const externalUrl = 'https://v2.spooky.fi/#/pools'
    window.open(externalUrl, '_blank')
  }

  const handleLinkClickSpooky = () => {
    const externalUrl = urlToV2
    window.open(externalUrl, '_blank')
  }

  return (
    <>
      {isFtm && (
        <>
          <MenuItem href={swapUrl} isActive={pathname.startsWith('/swap')} id="swap-nav-id">
            <SwapIcon height="24px" width="24px" fill="rgb(140, 140, 227)" />
            <Trans>Swap</Trans>
          </MenuItem>
          <MenuItem href={poolsUrl} dataTestId="pool-nav-link" isActive={isPoolActive} id="liquidity-nav-id">
            <PoolsIcon height="24px" width="24px" fill="rgb(115, 204, 231)" />
            <Trans>Liquidity</Trans>
          </MenuItem>

          {!shouldDisableNFTRoutes && (
            <MenuItem dataTestId="nft-nav" href="/nfts" isActive={isNftPage}>
              <Trans>NFTs</Trans>
            </MenuItem>
          )}
          <Box display={{ sm: 'flex', lg: 'flex', xxl: 'flex' }} width="full">
            <FarmsDropdown />
          </Box>
          {isFtm && !isConvertDisabled && (
            <Box display={{ sm: 'flex', lg: 'flex', xxl: 'flex' }} width="full">
              <MenuItem href={conversionUrl} isActive={pathname.startsWith('/conversion')} id="conversion-nav-id">
                <ConvertIcon height="24px" width="24px" color="rgb(255, 51, 75)" />
                <Trans>Convert</Trans>
              </MenuItem>
            </Box>
          )}

          <Box display={{ sm: 'none', lg: 'flex', xxl: 'flex' }} width="full">
            <ExternalMenuItem onClick={handleLinkClickStake} style={{ cursor: 'pointer' }}>
              <MenuItem href={pathname} isActive={false} id="stake-nav-id">
                <StakeBOOIcon height="24px" width="24px" fill="#FF0000" />
                <Trans>Stake BOO</Trans>
              </MenuItem>
            </ExternalMenuItem>
          </Box>
          {window.innerWidth > 800 && (
            <Box display={{ sm: 'none', lg: 'flex', xxl: 'flex' }} width="full">
              <MenuItem href={bridgeUrl} isActive={pathname.startsWith('/bridge')} id="bridge-nav-id">
                <BridgeIcon height="24px" width="24px" fill="rgb(115, 204, 231)" />
                <Trans>Bridge</Trans>
              </MenuItem>
            </Box>
          )}
          {window.innerWidth > 905 && (
            <Box display={{ sm: 'none', lg: 'flex', xxl: 'flex' }} width="full">
              <ExternalMenuItem onClick={handleLinkClickSpooky} style={{ cursor: 'pointer' }}>
                <MenuItem href={pathname} dataTestId="swap-nav-link" isActive={false} id="swap-nav-id">
                  <SpookyV2Icon height="24px" width="24px" fill="rgb(140, 140, 227)" />
                  <Trans>Spooky V2</Trans>
                </MenuItem>
              </ExternalMenuItem>
            </Box>
          )}
          <Box marginY="4">
            <MenuDropdown />
          </Box>
        </>
      )}
      {isAlternateChain && (
        <>
          <MenuItem href={swapUrl} isActive={pathname.startsWith('/swap')} id="swap-nav-id">
            <SwapIcon height="24px" width="24px" fill="rgb(140, 140, 227)" />
            <Trans>Swap</Trans>
          </MenuItem>
          <MenuItem href={poolsUrl} dataTestId="pool-nav-link" isActive={isPoolActive} id="liquidity-nav-id">
            <PoolsIcon height="24px" width="24px" fill="rgb(115, 204, 231)" />
            <Trans>Liquidity</Trans>
          </MenuItem>
          <Box display={{ sm: 'flex', lg: 'flex', xxl: 'flex' }} width="full">
            <FarmsDropdown />
          </Box>
          <Box display={{ sm: 'none', lg: 'flex', xxl: 'flex' }} width="full">
            <ExternalMenuItem onClick={handleLinkClickBridge} style={{ cursor: 'pointer' }}>
              <MenuItem href={pathname} dataTestId="pool-nav-link" isActive={false} id="liqduity-nav-id">
                <BridgeIcon height="24px" width="24px" fill="rgb(115, 204, 231)" />
                <Trans>Bridge</Trans>
              </MenuItem>
            </ExternalMenuItem>
          </Box>
          {window.innerWidth > 820 && (
            <Box display={{ sm: 'none', lg: 'flex', xxl: 'flex' }} width="full">
              <ExternalMenuItem onClick={handleLinkClickSpooky} style={{ cursor: 'pointer' }}>
                <MenuItem href={pathname} dataTestId="swap-nav-link" isActive={false} id="swap-nav-id">
                  <SpookyV2Icon height="24px" width="24px" fill="rgb(140, 140, 227)" />
                  <Trans>Spooky V2</Trans>
                </MenuItem>
              </ExternalMenuItem>
            </Box>
          )}
          <Box marginY="4">
            <MenuDropdown />
          </Box>
        </>
      )}
      {isNewBestSwapChain && (
        <>
          <MenuItem href={swapUrl} isActive={pathname.startsWith('/swap')} id="swap-nav-id">
            <SwapIcon height="24px" width="24px" fill="rgb(140, 140, 227)" />
            <Trans>Swap</Trans>
          </MenuItem>
          <Box marginY="4">
            <MenuDropdown />
          </Box>
        </>
      )}
    </>
  )
}

const Navbar = ({ blur }: { blur: boolean }) => {
  const isNftPage = useIsNftPage()
  const sellPageState = useProfilePageState((state) => state.state)
  const navigate = useNavigate()
  const isNavSearchInputVisible = useIsNavSearchInputVisible()

  const [accountDrawerOpen, toggleAccountDrawer] = useAccountDrawer()

  const handleSpookyIconClick = useCallback(() => {
    if (accountDrawerOpen) {
      toggleAccountDrawer()
    }
    navigate({
      pathname: '/',
      search: '?intro=true',
    })
  }, [accountDrawerOpen, navigate, toggleAccountDrawer])

  return (
    <>
      {blur && <Blur />}
      <Nav>
        <Box display="flex" height="full" flexWrap="nowrap">
          <Box className={styles.leftSideContainer}>
            <Box className={styles.logoContainer} onClick={handleSpookyIconClick}>
              <SpookyIcon width="32" height="32" data-testid="spookyswap-logo" />
              <SpookyIconContainer>
                <SpookySwapText fill="white" width="108px" height="24px" />
              </SpookyIconContainer>
            </Box>
            {!isNftPage && (
              <Box display={{ sm: 'flex', lg: 'none' }}>
                <ChainSelector leftAlign={true} />
              </Box>
            )}
            <Row display={{ sm: 'none', lg: 'flex' }}>
              <PageTabs />
            </Row>
          </Box>
          <Box
            className={styles.searchContainer}
            {...(isNavSearchInputVisible && {
              display: 'flex',
            })}
          >
            <SearchBar />
          </Box>
          <Box className={styles.rightSideContainer}>
            <Row gap="12">
              {/* <Box position="relative" display={isNavSearchInputVisible ? 'none' : { sm: 'flex' }}>
                <SearchBar />
              </Box> */}
              {isNftPage && sellPageState !== ProfilePageStateType.LISTING && <Bag />}
              {!isNftPage && (
                <Box display={{ sm: 'none', lg: 'flex' }}>
                  <ChainSelector />
                </Box>
              )}

              <Web3Status />
            </Row>
          </Box>
        </Box>
      </Nav>
    </>
  )
}

export default Navbar
