import { ChainId, Currency, CurrencyAmount, Token } from '@uniswap/sdk-core'
import { USDC_AXL_FANTOM, USDC_ON, USDT_BITTORRENT, WBTC_BITTORRENT } from '@uniswap/smart-order-router'
import { InsufficientReservesError, Pair } from '@uniswap/v2-sdk'
import { useWeb3React } from '@web3-react/core'
import { isSupportedChain } from 'constants/chains'
import { DEFAULT_CHAIN_ID } from 'constants/misc'
import { WRAPPED_NATIVE_CURRENCY } from 'constants/tokens'
import JSBI from 'jsbi'
// import { BASES_TO_CHECK_TRADES_AGAINST } from '@uniswap/smart-order-router/build/module/routers/legacy-router/bases'
import { useMemo } from 'react'
import { PriceMap } from 'state/cache/actions'
import { useCombinedActiveList } from 'state/lists/hooks'
import { ToDecimalsExpanded } from 'utils/currency'

import { useTokensFromMap } from './Tokens'
import { PairState, useV2Pairs } from './useV2Pairs'

const USD_LIQUIDITY_AMOUNT = JSBI.BigInt(10)

type PathMap = Map<Map<Map<string[][]>>>
type PoolInputMap = Map<PoolInputNode>
type Map<TValue> = { [key: string]: TValue }
type PoolOutputDetailMap = Map<PoolOutputDetail>
interface PoolInputNode {
  inputToken: Token
  outputMap: PoolOutputDetailMap
}

interface PoolOutputDetail {
  outputToken: Token
  //We do not need an array of details for v2, but in the future when we support v3
  // there are multiple routes for the same token
  details: PoolDetail
}

interface PoolDetail {
  poolAddress: string
  outputPair: Pair
  isReverse: boolean
}

function populatePairDetailMap(
  map: PoolInputMap,
  inputToken: Token,
  outputToken: Token,
  pair: Pair,
  isReverse: boolean
) {
  const inputKey = inputToken.address.toLowerCase()
  if (!map[inputKey]) {
    map[inputKey] = {
      inputToken,
      outputMap: {},
    }
  }

  const outputKey = outputToken.address.toLowerCase()
  const outputMap = map[inputKey].outputMap
  outputMap[outputKey] = {
    outputToken,
    details: {
      poolAddress: pair.liquidityToken.address.toLowerCase(),
      outputPair: pair,
      isReverse,
    },
  }
}

function getDefaultStableToken(chainId: number) {
  if (chainId === ChainId.BIT_TORRENT_MAINNET) {
    return USDT_BITTORRENT
  }
  const chainAllowed = isSupportedChain(chainId)
  if (!chainAllowed) {
    return USDC_AXL_FANTOM
  }
  return USDC_ON(chainId)
}

function getChainRoutingBases(chainId: number, stableQuoteToken: Token): Token[] {
  //TODO: expost Bases to check trades against in the subgraph-provider BASES_TO_CHECK_TRADES_AGAINST[chainId]
  const defaultRoutingBases = [WRAPPED_NATIVE_CURRENCY[chainId]!, stableQuoteToken]

  if (chainId == ChainId.BIT_TORRENT_MAINNET) {
    return defaultRoutingBases.concat([USDT_BITTORRENT, WBTC_BITTORRENT])
  }
  return defaultRoutingBases
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useTokenPriceCache(refreshCounter: number): PriceMap {
  const { chainId } = useWeb3React()
  const chainIdOrDefault = chainId ?? DEFAULT_CHAIN_ID
  const defaultListTokens = useCombinedActiveList()
  const appTokens = useTokensFromMap(defaultListTokens, chainId)
  const tokenKeys = useMemo(() => Object.keys(appTokens).map((k) => k.toLowerCase()), [appTokens])

  // We need atleast 3 bases to have proper
  // to have multiple reference points
  // You cannot triangular arbitrage without 3 bases, while covering the majority of routing.

  const stableQuoteToken = useMemo(() => getDefaultStableToken(chainIdOrDefault), [chainIdOrDefault])
  const bases = useMemo(() => {
    return getChainRoutingBases(chainIdOrDefault, stableQuoteToken)
  }, [chainIdOrDefault, stableQuoteToken])

  const allCurrencyPairs = useMemo((): [Currency | undefined, Currency | undefined][] => {
    if (!appTokens || !bases) return []
    return Object.values(appTokens).flatMap((t) =>
      bases
        .filter((b) => b && t && b.address.toLowerCase() != t.address.toLowerCase())
        .map((b) => [t as Currency, b as Currency] as [Currency | undefined, Currency | undefined])
    )
  }, [appTokens, bases])

  const allTokenPairs = useV2Pairs(allCurrencyPairs)

  const isDoneLoading = allTokenPairs.every((x) => x && x[0] != PairState.LOADING)
  // Intentionally omit allTokenPairs from the memo so that future request dependent on
  // Pairs can use a cached state
  const cachedTokenPairs = useMemo(
    () => allTokenPairs,
    // disable exhastive-deps since the we want to refresh on changes,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allCurrencyPairs, allTokenPairs?.length, isDoneLoading, refreshCounter]
  )

  const validTokenPairs = useMemo(() => {
    return cachedTokenPairs.filter((x) => x[0] == PairState.EXISTS).map((x) => x[1])
  }, [cachedTokenPairs])

  //

  const pairMap = useMemo(() => {
    const poolPairMap: PoolInputMap = validTokenPairs.reduce((result: PoolInputMap, currentValue) => {
      if (!currentValue) return result

      // Populates the pairs in both directions
      populatePairDetailMap(result, currentValue.token0, currentValue.token1, currentValue, false)
      populatePairDetailMap(result, currentValue.token1, currentValue.token0, currentValue, true)

      // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
      return result
    }, {})

    return poolPairMap
  }, [validTokenPairs])

  const bestQuoteResults = useMemo(() => {
    const quotes: PriceMap = {}
    if (0 == Object.keys(pairMap).length) {
      return quotes
    }

    // const quotes: { [address: string]: CurrencyAmount<Token> } = {}
    if (!stableQuoteToken) return quotes

    const stableDecimalFactor = ToDecimalsExpanded(stableQuoteToken.decimals)
    const rawQuoteAmount = JSBI.multiply(USD_LIQUIDITY_AMOUNT, stableDecimalFactor)
    const liquidityAmount = CurrencyAmount.fromRawAmount(stableQuoteToken, rawQuoteAmount)
    const stableTokenAddressLowered = stableQuoteToken.address.toLowerCase()

    // initialize quotes
    // tokenKeys.forEach((k) => (quotes[k] = CurrencyAmount.fromRawAmount(stableQuoteToken, 0)))
    tokenKeys.forEach((k) => (quotes[k] = 0))

    //TODO: this should be using Depth First Search and limited by the number of hops, instead of hard coded
    for (const inputKey of tokenKeys) {
      const input = pairMap[inputKey]
      if (!input) {
        continue
      }

      // console.log(`Gettting Prices for ${input.inputToken.symbol}`)

      let bestInputAmountForStable: CurrencyAmount<Token> | undefined = undefined
      if (input.outputMap[stableTokenAddressLowered]) {
        const pairRoute = [input.outputMap[stableTokenAddressLowered].details.outputPair]
        const routeQuote = calculateQuoteChainValue(liquidityAmount, pairRoute)

        if (routeQuote && (!bestInputAmountForStable || routeQuote.lessThan(bestInputAmountForStable))) {
          bestInputAmountForStable = routeQuote
        }
      }

      //We should factor this out in a more generic way so we can enumerate by hops
      for (const outputKey in input.outputMap) {
        const outputToStable = pairMap[outputKey]
        const outputStablePair = outputToStable.outputMap[stableTokenAddressLowered]?.details.outputPair
        if (!outputStablePair) {
          continue
        }

        const outputToInputPair = input.outputMap[outputKey].details.outputPair
        const outputPairRoute = [outputStablePair, outputToInputPair]
        const outputRouteQuote = calculateQuoteChainValue(liquidityAmount, outputPairRoute)
        if (!outputRouteQuote) {
          continue
        }

        if (!bestInputAmountForStable || outputRouteQuote.lessThan(bestInputAmountForStable)) {
          const oldPriceMessage = bestInputAmountForStable
            ? `${bestInputAmountForStable.currency.symbol} ${toUSD(USD_LIQUIDITY_AMOUNT, bestInputAmountForStable)}`
            : 'undefined'

          // console.log(`\tOld Price ${oldPriceMessage}`)
          // console.log(`\tNew Price ${toUSD(USD_LIQUIDITY_AMOUNT, outputRouteQuote)}`)
          // console.log(
          //   `\tRoute: ${outputStablePair.token0.symbol}/${outputStablePair.token1.symbol}  <= ${outputToInputPair.token0.symbol}/${outputToInputPair.token1.symbol}`
          // )

          bestInputAmountForStable = outputRouteQuote
        }
      }

      // Since were quoting in usd we should be safe to convert to number because nothing should have a value greater in usd than the largest number in javascript
      quotes[inputKey] = toUSD(
        USD_LIQUIDITY_AMOUNT,
        bestInputAmountForStable ?? CurrencyAmount.fromRawAmount(input.inputToken, 0)
      )

      // console.log(`\tFinal Price of ${input.inputToken.symbol} is ${quotes[inputKey]}`)
    }

    // console.log(quotes)
    return quotes
  }, [pairMap, stableQuoteToken, tokenKeys])

  return bestQuoteResults

  //The Pair map should contain all pairs from bases, we need to combine all possible path combinations up to X hop deep.
  //Then, calculate the value based on the most liquid route.  Note since were using Pairs we should not have to enumerate the
  //Since we are to assume that the stable coins are pegged at a dollar (somewhere in the market).  We should start from the Stable Quote token and work our way out.
  // by enumerating the base tokens, we can get all the of prices of a token in comparison to its base, however we need to quote given some minimum liquidity requirement to assure
  // we're not quoting using pools with not enough liquidity to provide a valid quote.
}

function toUSD(originalLiquidityAmount: JSBI, outputAmount: CurrencyAmount<Token>) {
  return 1 / Number(outputAmount.divide(originalLiquidityAmount).toSignificant())
}

const debug = false
const debugRoutes = false
function calculateQuoteChainValue(currencyAmount: CurrencyAmount<Token>, pairRoute: Pair[]) {
  let quoteAmount = currencyAmount
  for (const pair of pairRoute) {
    let priceQuote = undefined
    try {
      priceQuote = pair.getInputAmount(quoteAmount, false)[0]
    } catch (error) {
      if (error instanceof InsufficientReservesError) {
        // console.log('InsufficientReserves')
      } else {
        console.log(error)
      }
      return undefined
    }
    // const priceOfQuote = pair.priceOf(quoteAmount.currency)

    // //TODO: fix this Quote doesn't actually quote using the par
    // // Quote just returns the spot price multipled by the currency amount
    // const priceQuote = priceOfQuote.quote(quoteAmount)
    quoteAmount = priceQuote

    if (debugRoutes) {
      const outputToken =
        pair.token0.address.toLowerCase() == currencyAmount.currency.address.toLowerCase() ? pair.token0 : pair.token1
      console.log(`\tPrice Of ${quoteAmount.currency.symbol}: ${priceQuote.toSignificant()} in ${outputToken.symbol}`)
    }
  }

  if (debug) {
    console.log(
      `${formatCurrenyForLogs(currencyAmount)} Quoted For ${formatCurrenyForLogs(quoteAmount)} Inverted: ${
        1 / Number(quoteAmount.toSignificant())
      }`
    )
  }
  return quoteAmount
}

function formatCurrenyForLogs(currencyAmount: CurrencyAmount<Token>) {
  //Currency amounts to significant automatically factor the decimals, while using the quote as a raw fraction wont
  const noramlizedAmmount = currencyAmount.toSignificant()
  return `${noramlizedAmmount} ${currencyAmount.currency.symbol} ${currencyAmount.currency.address}`
}
