import { BigNumberish } from '@ethersproject/bignumber'
import { Trans } from '@lingui/macro'
import { BigintIsh, Currency, CurrencyAmount, Percent, Token } from '@uniswap/sdk-core'
import { MAX_UINT160 } from '@uniswap/smart-order-router'
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Pool, Position } from '@uniswap/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import { Trace } from 'analytics'
import Badge, { BadgeVariant } from 'components/Badge'
import { DarkCard, LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import InvalidFarmDetails from 'components/Farms/FarmDetails/InvalidFarmDetails'
import FarmDetailsSkeleton from 'components/Farms/FarmDetails/Skeleton'
import { SkeletonRow } from 'components/NavBar/SuggestionRow'
import { RowBetween, RowFixed, RowFlat } from 'components/Row'
import { PageWrapper } from 'components/swap/styled'
import { Label } from 'components/swap/SwapModalHeaderAmount'
import { BreadcrumbNavLink } from 'components/Tokens/TokenDetails/BreadcrumbNavLink'
import { Hr } from 'components/Tokens/TokenDetails/Skeleton'
import { MouseoverTooltip } from 'components/Tooltip'
import TransactionConfirmationModal, { ConfirmationModalContent } from 'components/TransactionConfirmationModal'
import { DAYS_IN_YEAR } from 'constants/misc'
import { DEFAULT_ERC20_DECIMALS } from 'constants/tokens'
import { useFarmV3Helper, usePoolV3Contract, useV3NFTPositionManagerContract } from 'hooks/useContract'
import { usePairAddresstoCurrency } from 'hooks/usePairAddresstoCurrency'
import { useV3Positions, useV3PositionsFromFarm } from 'hooks/useV3Positions'
import JSBI from 'jsbi'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { useCurrencyFromMap, useTokenFromActiveNetwork } from 'lib/hooks/useCurrency'
import { themeVars } from 'nft/css/sprinkles.css'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ArrowLeft } from 'react-feather'
import { Text } from 'rebass'
import { PriceMap } from 'state/cache/actions'
import { useUSDPricesWithFallback } from 'state/cache/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled from 'styled-components'
import { ThemedText } from 'theme'
import { PositionDetails } from 'types/position'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { formatUSDPrice } from 'utils/formatNumbers'

import { useV3FarmIncentives, useV3UserPositions } from '../hooks/fetchFarms'
import { LinkedCurrency, PositionMetadata } from '../hooks/misc'
import { ConfirmAddFarmModalBottom } from './ConfirmAddModalBottom'
import { PositionsTable } from './PositionsTable'

enum FarmAction {
  Add = 1,
  Remove = 2,
  Claim = 3,
}

export interface Incentive {
  rewardToken: string
  id: string
  pool: string
  startTime: string | BigNumberish
  endTime: string | BigNumberish
  refundee: string
}

function actionToDisplayName(action: FarmAction | undefined) {
  if (action === FarmAction.Add) return 'Add'
  if (action === FarmAction.Remove) return 'Remove'
  if (action === FarmAction.Claim) return 'Claim'
  return 'Unknown'
}

function ToUSD(priceMap: PriceMap, currencyAmount: CurrencyAmount<Token> | undefined) {
  if (!currencyAmount) {
    return 0
  }
  const currencyKey = currencyAmount.currency.address.toLowerCase()
  const tokenPrice = priceMap[currencyKey] ?? 0

  if (tokenPrice == 0) {
    console.log('could not find usd amount for pair ' + currencyKey)
  }
  return Number(currencyAmount.toSignificant()) * tokenPrice
}

// eslint-disable-next-line import/no-unused-modules
export function useGetTVL(pool: Pool | undefined) {
  const [tvl, setTvl] = useState<number | undefined>(0)
  const cachedTokenPrices = useUSDPricesWithFallback()

  const unistaker = useFarmV3Helper()

  // Always call the hook at the top level
  const { loading: loadingPositions, positions } = useV3Positions(unistaker ? unistaker.address : undefined)

  const filteredPositions = useMemo(() => {
    if (!positions || !pool) {
      return []
    }

    return positions.filter((position: PositionDetails) => {
      return (
        position.fee === pool.fee && position.token0 === pool.token0.address && position.token1 === pool.token1.address
      )
    })
  }, [positions, pool])

  useEffect(() => {
    if (!loadingPositions && filteredPositions && filteredPositions.length > 0 && pool) {
      const tvls = filteredPositions.map((position: PositionDetails) => {
        const pos = new Position({
          pool,
          liquidity: JSBI.BigInt(position.liquidity),
          tickLower: position.tickLower,
          tickUpper: position.tickUpper,
        })

        const amount0 = pos.amount0
        const amount1 = pos.amount1
        const usdToken0 = ToUSD(cachedTokenPrices, amount0)
        const usdToken1 = ToUSD(cachedTokenPrices, amount1)

        return usdToken0 + usdToken1
      })

      const totalTvl = tvls.reduce((a, b) => a + b, 0)
      setTvl(totalTvl)
    }
  }, [cachedTokenPrices, filteredPositions, loadingPositions, pool])
  // Return the TVL or any other relevant information from this hook
  return tvl
}

type FarmDetailsProps = {
  poolKey?: string
}
// eslint-disable-next-line import/no-unused-modules
export default function FarmDetails({ poolKey }: FarmDetailsProps) {
  if (!poolKey) {
    throw new Error('Invalid Farm details route: one of the currency param is undefined')
  }

  const { account, chainId, provider } = useWeb3React()

  const addTransaction = useTransactionAdder()
  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState('')
  const rewardsToClaim = MAX_UINT160.toString()
  const [farmActionState, setFarmActionState] = useState(FarmAction.Add)
  const [tokenSelect, setTokenSelect] = useState('')

  const [pool, setPool] = useState<Pool | undefined>()
  const nftPositionManager = useV3NFTPositionManagerContract()
  const { positions: userPositions, refetch: refetchUserPositions } = useV3UserPositions(account)
  const { positions: userPositionsUser, loading: loadingPositions } = useV3PositionsFromFarm(account, pool)

  const { incentives }: { incentives: Incentive[] } = useV3FarmIncentives(poolKey)
  const poolAddress = incentives?.[0]?.pool
  const endTime = incentives?.[0]?.endTime

  const remainingDays = useMemo(() => {
    if (!endTime) {
      return undefined
    }

    const now = Math.floor(Date.now() / 1000) // current time in seconds
    const remainingSeconds = Number(endTime) - now
    const remainingDays = Math.ceil(remainingSeconds / (24 * 60 * 60)) // convert seconds to days

    return remainingDays > 0 ? remainingDays : 0 // if remainingDays is negative, return 0
  }, [endTime])

  // TODO : multicall poolV3 contract to get slot0, liquidity, token0, token1, fee
  const poolV3Contract = usePoolV3Contract(poolAddress)
  const slot0Result = useSingleCallResult(poolV3Contract, 'slot0', [])
  const liquidityResult = useSingleCallResult(poolV3Contract, 'liquidity', [])
  const token0Result = useSingleCallResult(poolV3Contract, 'token0', [])
  const token1Result = useSingleCallResult(poolV3Contract, 'token1', [])
  const feeResult = useSingleCallResult(poolV3Contract, 'fee', [])
  const token0Address = token0Result?.result?.[0] ?? '0x'
  const token1Address = token1Result?.result?.[0] ?? '0x'
  const [currencyA, currencyB] = usePairAddresstoCurrency(token0Address, token1Address)

  const rewardToken = incentives?.[0]?.rewardToken
  const rewardTokenQuery = useTokenFromActiveNetwork(rewardToken) as Token
  const rewardCurrencyQuery = useCurrencyFromMap({ [rewardToken ?? '']: rewardTokenQuery }, chainId, rewardToken) as
    | Currency
    | undefined

  const UniV3StakerContract = useFarmV3Helper()
  const rewardResult = useSingleCallResult(UniV3StakerContract, 'incentives', [poolKey])
  const rewardTokensleft = rewardResult?.result?.totalRewardUnclaimed as BigintIsh
  const rewardTokensLeftExact =
    rewardTokensleft && rewardCurrencyQuery
      ? CurrencyAmount.fromRawAmount(rewardCurrencyQuery, rewardTokensleft).toExact()
      : '0'

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setTxHash('')
  }, [])
  const setupPool = useCallback(
    (token0Result: any, token1Result: any, slot0Result: any, liquidityResult: any, chainId: number) => {
      if (
        !token0Result.pending &&
        !token1Result.pending &&
        !token0Result.loading &&
        !token1Result.loading &&
        !feeResult.loading &&
        token0Result.result &&
        token1Result.result &&
        slot0Result.result &&
        liquidityResult.result &&
        feeResult.result
      ) {
        const tokenA = new Token(chainId, token0Result.result[0], currencyA?.decimals ?? DEFAULT_ERC20_DECIMALS)
        const tokenB = new Token(chainId, token1Result.result[0], currencyB?.decimals ?? DEFAULT_ERC20_DECIMALS)
        const newPool = new Pool(
          tokenA,
          tokenB,
          feeResult.result[0],
          JSBI.BigInt(slot0Result.result.sqrtPriceX96.toString()),
          JSBI.BigInt(liquidityResult.result.toString()),
          slot0Result.result.tick
        ) // Mocked values for sqrtPriceX96, liquidity, tick
        setPool(newPool)
      }
    },
    [currencyA, currencyB, feeResult, setPool]
  )

  useEffect(() => {
    if (token0Result && token1Result && slot0Result && liquidityResult && chainId) {
      setupPool(token0Result, token1Result, slot0Result, liquidityResult, chainId)
    }
  }, [token0Result, token1Result, chainId, setupPool, slot0Result, liquidityResult])

  const cachedTokenPrices = useUSDPricesWithFallback()

  const tvlAmount = useGetTVL(pool)
  const rewardsUSD = ToUSD(
    cachedTokenPrices,
    rewardTokensleft && rewardTokenQuery
      ? CurrencyAmount.fromRawAmount(rewardTokenQuery, rewardTokensleft ? rewardTokensleft : 0)
      : undefined
  )

  // compute apr knowing total value locked (min is 1), total rewarsd left and days let in pool
  const apr =
    rewardsUSD > 0 && tvlAmount
      ? (rewardsUSD / (tvlAmount > 1 ? tvlAmount : 1)) * (DAYS_IN_YEAR / (remainingDays ?? 1))
      : 0

  const { descriptiveAction, formattedTokenName, formattedPendingText } = useMemo(() => {
    const formattedTokenName = `${currencyA?.symbol} / ${currencyB?.symbol}`

    const descriptiveAction = actionToDisplayName(farmActionState)

    const formattedPendingText = `${descriptiveAction}  ${formattedTokenName} ${
      farmActionState === FarmAction.Claim ? ' Rewards' : ''
    }`
    return {
      descriptiveAction,
      formattedTokenName,
      formattedPendingText,
    }
  }, [farmActionState, currencyA, currencyB])

  const pendingText = (
    <AutoColumn gap="8px" justify="center">
      <Trans>{formattedPendingText}</Trans> <Badge variant={BadgeVariant.PRIMARY}>Token ID {tokenSelect}</Badge>
    </AutoColumn>
  )

  async function onExecute() {
    if (!chainId || !provider || !account || !UniV3StakerContract || !nftPositionManager || !incentives || !poolKey) {
      console.error('Missing required parameters')
      return
    }

    setAttemptingTxn(true)

    try {
      if (farmActionState === FarmAction.Add) {
        await handleAddAction()
      } else if (farmActionState === FarmAction.Remove) {
        await handleRemoveAction()
      } else if (farmActionState === FarmAction.Claim) {
        await handleClaimAction()
      } else {
        console.error('Invalid farm action state')
      }
    } catch (error) {
      console.error('Execution failed:', error)
    } finally {
      setAttemptingTxn(false)
      // setShowConfirm(false)
      setTimeout(() => {
        console.log('Refetching user positions')
        refetchUserPositions()
      }, 15000)
    }
  }

  async function handleAddAction() {
    if (!pool || !incentives || !nftPositionManager || !UniV3StakerContract) {
      console.error('Missing required parameters')
      return
    }
    const encodeArgData = UniV3StakerContract?.interface._abiCoder.encode(
      ['address', 'address', 'uint256', 'uint256', 'address'],
      [
        incentives[0].rewardToken,
        incentives[0].pool,
        incentives[0].startTime,
        incentives[0].endTime,
        incentives[0].refundee,
      ]
    )

    const transferArgs = [account, UniV3StakerContract?.address, tokenSelect, encodeArgData] as [
      string,
      string,
      string,
      string
    ]
    //encode incentive key params
    const estimatedGas = await nftPositionManager.estimateGas['safeTransferFrom(address,address,uint256,bytes)'](
      ...transferArgs
    )
    const safeGasEstimate = calculateGasMargin(estimatedGas)
    const transferTx = await nftPositionManager['safeTransferFrom(address,address,uint256,bytes)'](...transferArgs, {
      gasLimit: safeGasEstimate,
    })
    const res = await transferTx?.wait()

    if (!res?.status || !transferTx) {
      console.error('NFT transfer failed')
      new Error('NFT transfer failed')
    }
    if (res) {
      setTxHash(res.transactionHash)
      addTransaction(transferTx, {
        type: TransactionType.ADD_LIQUIDITY_V3_FARM,
        baseCurrencyId: pool?.token0.address,
        quoteCurrencyId: pool?.token1.address,
        expectedTokenId: tokenSelect,
      })
    }

    console.log('NFT transfer successful')
  }

  async function handleRemoveAction() {
    // Prepare arguments for unstaking and withdrawing
    if (!incentives || !UniV3StakerContract || !pool) {
      console.error('Missing required parameters')
      return
    }
    const incentive = incentives[0]
    const args: [
      { rewardToken: string; pool: string; startTime: BigNumberish; endTime: BigNumberish; refundee: string },
      string
    ] = [
      {
        rewardToken: incentive.rewardToken,
        pool: incentive.pool,
        startTime: incentive.startTime,
        endTime: incentive.endTime,
        refundee: incentive.refundee,
      },
      tokenSelect,
    ]
    const methodName = 'unstakeToken'

    const estimatedGas = await UniV3StakerContract?.estimateGas[methodName](...args)
    const safeGasEstimate = calculateGasMargin(estimatedGas)

    const tx = await UniV3StakerContract?.[methodName](...args, { gasLimit: safeGasEstimate })
    const res1 = await tx?.wait()

    if (!res1?.status) {
      console.error('Unstake failed')
      new Error('Unstake failed')
    }

    // withdraw Token to Owner
    const args_withdraw = [tokenSelect, account, '0x'] as [string, string, string]
    const methodName2 = 'withdrawToken'

    const esimatedGas2 = await UniV3StakerContract?.estimateGas[methodName2](...args_withdraw)
    const safeGasEstimate2 = calculateGasMargin(esimatedGas2)
    const tx2 = await UniV3StakerContract?.[methodName2](...args_withdraw, { gasLimit: safeGasEstimate2 })
    const res2 = await tx2?.wait()

    if (!res2?.status || !res1?.status) {
      console.error('Withdraw failed')
      new Error('Withdraw failed')
    } else {
      console.log('Unstake and withdraw successful')
      setTxHash(res2?.transactionHash)
    }
    addTransaction(tx, {
      type: TransactionType.REMOVE_FARM_V3,
      baseCurrencyId: pool.token0.address,
      quoteCurrencyId: pool.token1.address,
      expectedTokenId: tokenSelect,
    })
    console.log('Unstake and withdraw successful')
  }

  async function handleClaimAction() {
    if (!incentives || !UniV3StakerContract || !account) {
      console.error('Missing required parameters')
      return
    }
    // Prepare arguments for claiming rewards
    const incentive = incentives[0] as Incentive
    const address = account as string
    const args: [string, string, string] = [incentive.rewardToken, address, rewardsToClaim]
    const methodName = 'claimReward'

    const estimatedGas = await UniV3StakerContract?.estimateGas[methodName](...args)
    const safeGasEstimate = calculateGasMargin(estimatedGas)

    const tx = await UniV3StakerContract?.[methodName](...args, { gasLimit: safeGasEstimate })
    const res = await tx?.wait()

    if (!res?.status || !res) {
      console.error('Claim rewards failed')
      new Error('Claim rewards failed')
    } else {
      setTxHash(res.transactionHash)
    }

    addTransaction(tx, {
      type: TransactionType.CLAIM,
      recipient: account,
      farmAmountRaw: rewardsToClaim,
    })

    console.log('Claim rewards successful')
  }

  const modalHeader = () => {
    const positionNFT = { tokenId: tokenSelect }
    return (
      <AutoColumn gap="20px">
        <LightCard mt="20px" $borderRadius="20px">
          <RowFlat style={{ alignItems: 'center', gap: '15px' }}>
            <Text fontSize="32px" fontWeight={500} lineHeight="42px">
              {descriptiveAction} {formattedTokenName}
            </Text>

            <PositionMetadata height={100} position={positionNFT} />
          </RowFlat>
        </LightCard>
      </AutoColumn>
    )
  }

  const modalBottom = () => {
    if (currencyA && currencyB) {
      return (
        <ConfirmAddFarmModalBottom
          formattedTokenName={formattedTokenName}
          lpToken={currencyB}
          quoteToken={currencyA}
          farmAction={farmActionState}
          onExecute={onExecute}
        />
      )
    } else {
      return <SkeletonRow />
    }
  }

  // address will never be undefined if token is defined; address is checked here to appease typechecker
  if (!poolAddress || !currencyA || !currencyB || !pool) {
    return <InvalidFarmDetails pageChainId={chainId ? chainId : 1} isInvalidAddress={!pool} />
  }

  return (
    <Trace
      page="v3-farm-details-page"
      properties={{ tokenAddress: poolAddress, tokenName: currencyA }}
      shouldLogImpression
    >
      <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={attemptingTxn}
        hash={txHash}
        reviewContent={() => (
          <ConfirmationModalContent
            title={<Trans>Performing action on a farm</Trans>}
            onDismiss={handleDismissConfirmation}
            topContent={modalHeader}
            bottomContent={modalBottom}
          />
        )}
        pendingText={pendingText}
      />
      <PageWrapper
        style={{
          maxWidth: '780px',
        }}
      >
        {poolAddress && pool ? (
          <AutoColumn gap="sm" style={{ width: '100%', height: '100%' }}>
            <BreadcrumbNavLink to="/farms/v3">
              <ArrowLeft data-testid="token-details-return-button" size={14} /> Farms
            </BreadcrumbNavLink>
            <ResponsiveRow>
              <PositionLabelRow width="100% !important">
                <RowBetween width="100%">
                  <RowFixed>
                    <DoubleCurrencyLogo currency0={currencyA} currency1={currencyB} size={24} margin={true} />
                    <ThemedText.DeprecatedLabel fontSize="24px" mr="10px">
                      &nbsp;{currencyA?.symbol}&nbsp;/&nbsp;{currencyB?.symbol}
                    </ThemedText.DeprecatedLabel>
                    <Badge style={{ marginRight: '8px' }}>
                      <BadgeText>
                        <Trans>{new Percent(pool.fee, 1_000_000).toSignificant()}%</Trans>
                      </BadgeText>
                    </Badge>
                  </RowFixed>
                  <BadgeWrapper>
                    <MouseoverTooltip text={<Trans>The farm is active and rewards are available to claim</Trans>}>
                      <LabelText color={themeVars.colors.success}>
                        {remainingDays !== undefined && remainingDays > 0 ? (
                          <Badge style={{ gap: '8px' }}>
                            <BadgeText>
                              <Trans> Active Farm</Trans>
                            </BadgeText>{' '}
                            <ActiveDot />
                          </Badge>
                        ) : (
                          <Badge style={{ gap: '8px' }}>
                            <BadgeText>
                              <Trans> Farm Ended </Trans>
                            </BadgeText>
                          </Badge>
                        )}
                      </LabelText>
                    </MouseoverTooltip>
                  </BadgeWrapper>
                </RowBetween>
              </PositionLabelRow>
            </ResponsiveRow>

            <Hr />

            <DarkCard>
              <AutoColumn gap="md" style={{ width: '100%' }}>
                <RowBetween
                  align="flex-start
                "
                >
                  <AutoColumn justify="space-between" grow={true}>
                    <Label>
                      <Trans>Total Value Locked</Trans>
                    </Label>
                    <ThemedText.DeprecatedLargeHeader fontSize="36px" fontWeight={535}>
                      <Trans>{tvlAmount && tvlAmount < 0.1 ? '<$0.1' : formatUSDPrice(tvlAmount)} </Trans>
                    </ThemedText.DeprecatedLargeHeader>
                  </AutoColumn>
                  <MouseoverTooltip
                    text={
                      remainingDays !== undefined ? (
                        remainingDays === 0 ? (
                          <Trans>Farm has ended</Trans>
                        ) : (
                          <Trans>
                            Farm Ends in {remainingDays} {remainingDays === 1 ? 'Day' : 'Days'}
                          </Trans>
                        )
                      ) : (
                        <Trans>Farm has ended</Trans>
                      )
                    }
                    style={{
                      justifyContent: 'right',
                      justifyItems: 'flex-start',
                      alignItems: 'flex-start',
                      alignSelf: 'flex-start',
                    }}
                  >
                    <Badge style={{ marginRight: '8px' }} variant={BadgeVariant.FARM_BRANDED}>
                      <BadgeText style={{ color: '#d2cece' }}>
                        {remainingDays !== undefined ? (
                          remainingDays === 0 ? (
                            <Trans>Farm has ended</Trans>
                          ) : (
                            <Trans>
                              Farm Ends in {remainingDays} {remainingDays === 1 ? 'Day' : 'Days'}
                            </Trans>
                          )
                        ) : (
                          <Trans>Farm Ended</Trans>
                        )}
                      </BadgeText>
                    </Badge>
                  </MouseoverTooltip>
                </RowBetween>
                <RowFlat style={{ gap: '16px' }}>
                  <AutoColumn gap="md" style={{ width: '50%' }}>
                    <Label>
                      <Trans>Total Rewards in Farm</Trans>
                    </Label>
                    <LightCard padding="12px 16px">
                      <AutoColumn gap="md">
                        <RowBetween>
                          {rewardTokenQuery && <LinkedCurrency chainId={chainId} currency={rewardTokenQuery} />}

                          <RowFixed>
                            <ThemedText.DeprecatedMain>
                              {Number(rewardTokensLeftExact) < 0.1 ? '<0.1' : Number(rewardTokensLeftExact).toFixed(2)}{' '}
                            </ThemedText.DeprecatedMain>
                          </RowFixed>
                        </RowBetween>
                      </AutoColumn>
                    </LightCard>
                  </AutoColumn>
                  <AutoColumn gap="md" style={{ width: '50%' }}>
                    <Label style={{ justifySelf: 'right' }}>
                      <Trans>Historical APR (%)</Trans>
                    </Label>
                    <LightCard padding="12px 16px">
                      <AutoColumn gap="md">
                        <RowFixed justifySelf="right">
                          <ThemedText.BodyPrimary>
                            {apr ? parseFloat(apr.toString()).toFixed(2) : 'undefined'}
                          </ThemedText.BodyPrimary>
                        </RowFixed>
                      </AutoColumn>
                    </LightCard>
                  </AutoColumn>
                </RowFlat>

                <Label>
                  <Trans>Your NFTs</Trans>
                </Label>
                <LightCard padding="12px 12px" display="flex" cellSpacing="5px">
                  {userPositionsUser && userPositionsUser.length > 0 ? (
                    userPositionsUser.map((position) => {
                      return (
                        <PositionContainer position={position} key={position?.tokenId.toNumber()}>
                          <PositionMetadata key={position?.tokenId.toNumber()} position={position} height={100} />
                        </PositionContainer>
                      )
                    })
                  ) : (
                    <NoNFTDisplay>No Positions Available</NoNFTDisplay>
                  )}
                </LightCard>
              </AutoColumn>
            </DarkCard>
            {userPositions && (
              <PositionsTable
                positions={userPositions}
                setModal={setShowConfirm}
                setFarmActionState={setFarmActionState}
                setToken={setTokenSelect}
                // setReward={setRewardsToClaim}
                incentive={incentives?.[0]}
                pool={pool}
              />
            )}
          </AutoColumn>
        ) : (
          <FarmDetailsSkeleton />
        )}
      </PageWrapper>
    </Trace>
  )
}

const ResponsiveRow = styled(RowBetween)`
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
    width: 100%;
  }
`

const PositionLabelRow = styled(RowFixed)({
  flexWrap: 'wrap',
  gap: 8,
})

const ActiveDot = styled.span`
  background-color: ${({ theme }) => theme.success};
  border-radius: 50%;
  height: 8px;
  width: 8px;
`

const LabelText = styled.div<{ color: string }>`
  align-items: center;
  color: ${({ color }) => color} !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`
const BadgeWrapper = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
`

const BadgeText = styled.div`
  font-weight: 535;
  font-size: 14px;
  color: ${({ theme }) => theme.neutral2};
`

const NoNFTDisplay = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 60px;
  color: ${({ theme }) => theme.neutral2};
  font-size: 16px;
  font-weight: 535;
  align-items: center;
  padding: 0px 28px;
  gap: 8px;
`
type PositionContainerProps = {
  position: PositionDetails
}

const PositionContainer = styled.div<PositionContainerProps>`
  &:hover {
    brightness: 1.5;
  }
`
