import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import Row from 'components/Row'
import { bestTradeOptions, clientSwapOptions, LiquidityHub } from 'components/swap/LiquidityHub'
import { BestSwapNewChains } from 'constants/chains'
import { useCallback, useState } from 'react'
import styled from 'styled-components'

import { SwapRouterOption, SwapRouterOptions } from '../../swap/LiquidityHub/types'

const RoutingSettingsContainer = styled(Row)`
  justify-content: space-between;
`

export default function RoutingSettings() {
  const { chainId: connectedChainId } = useWeb3React()

  let tradeOptions = bestTradeOptions
  if (localStorage.getItem('routingOption') === '/best-swap') {
    tradeOptions = bestTradeOptions
  } else {
    tradeOptions = clientSwapOptions
  }
  const initialSelectedOption = tradeOptions[0]

  const [selectedOption, setSelectedOption] = useState<SwapRouterOption>(initialSelectedOption)

  const onSelect = useCallback((option: SwapRouterOptions) => {
    const selected = bestTradeOptions.find((opt) => opt.value === option) || bestTradeOptions[0]
    setSelectedOption(selected)
    sessionStorage.setItem('routerOption', option.toString())
    window.dispatchEvent(new Event('storage'))
  }, [])

  return (
    <RoutingSettingsContainer>
      <LiquidityHub.RouterSelect
        options={tradeOptions}
        selected={selectedOption.value}
        onSelect={onSelect}
        outsider={BestSwapNewChains.includes(connectedChainId ? connectedChainId : ChainId.FANTOM)}
      />
    </RoutingSettingsContainer>
  )
}
