import { Trans } from '@lingui/macro'
import { MAX_WIDTH_MEDIA_BREAKPOINT } from 'components/Tokens/constants'
import { ReactNode, useEffect, useState } from 'react'
import { AlertTriangle } from 'react-feather'
import { useUSDPricesWithFallback } from 'state/cache/hooks'
import styled from 'styled-components'
import { isV3FarmDisabled } from 'utils/env'

import { Incentive } from '../FarmDetails/details'
import { useV3FarmIncentivesAll } from '../hooks/fetchFarms'
import { HeaderRowComponent, LoadedFarmRow } from './FarmRow'

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  background-color: ${({ theme }) => theme.surface1};
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.surface3};
`

const FarmDataContainer = styled.div`
  & > div:nth-child(odd) {
    background: transparent;
  }

  & > div:nth-child(even) {
    background-color: transparent;
  }

  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

const NoFarmDisplay = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 60px;
  color: ${({ theme }) => theme.neutral2};
  font-size: 16px;
  font-weight: 535;
  align-items: center;
  padding: 0px 28px;
  gap: 8px;
`

function NoFarmsState({ message }: { message: ReactNode }) {
  return (
    <GridContainer>
      <HeaderRowComponent />
      <NoFarmDisplay>{message}</NoFarmDisplay>
    </GridContainer>
  )
}

export default function FarmTable() {
  // const { farms, farmSortRank, loadingFarms } = useStaticFarms()
  const [validFarms, setValidFarms] = useState<Incentive[]>([])
  const { loading, incentives, error } = useV3FarmIncentivesAll()
  const cachedTokenPrices = useUSDPricesWithFallback()

  useEffect(() => {
    const now = Date.now() / 1000

    setValidFarms(incentives?.filter((farm) => farm?.endTime > now && farm?.startTime < now) || [])
  }, [incentives])

  // Rendering Logic
  if (loading && !incentives) {
    return null // Or a placeholder component
  }
  if (error) {
    return (
      <NoFarmsState
        message={
          <>
            <AlertTriangle size={16} />
            <Trans>An error occurred loading Farms. Please try again.</Trans>
          </>
        }
      />
    )
  }

  if (incentives?.length === 0 || validFarms?.length === 0 || isV3FarmDisabled() === true) {
    return <NoFarmsState message={<Trans>No Farms found</Trans>} />
  }

  // Render Farm Data
  return (
    <GridContainer>
      <HeaderRowComponent />
      <FarmDataContainer>
        {validFarms?.map(
          (farm, index) =>
            farm?.id && (
              <LoadedFarmRow
                key={farm?.id}
                farmListIndex={index}
                farmListLength={validFarms.length}
                farm={farm}
                priceMap={cachedTokenPrices}
                // TODO: fix ranking
                sortRank={1}
              />
            )
        )}
      </FarmDataContainer>
    </GridContainer>
  )
}
