import { Trans } from '@lingui/macro'
import { QuoteResponse } from '@orbs-network/liquidity-hub-ui-sdk'
import { SwapSide } from '@paraswap/sdk'
import { OptimalRate } from '@paraswap/sdk'
import {
  BrowserEvent,
  InterfaceElementName,
  InterfaceEventName,
  InterfacePageName,
  InterfaceSectionName,
  SharedEventName,
  SwapEventName,
} from '@uniswap/analytics-events'
import { ChainId, Currency, CurrencyAmount, Percent, Price, Token, TradeType } from '@uniswap/sdk-core'
import { UNIVERSAL_ROUTER_ADDRESS } from '@uniswap/universal-router-sdk'
import { useWeb3React } from '@web3-react/core'
import { sendAnalyticsEvent, Trace, TraceEvent, useTrace } from 'analytics'
import BN from 'bignumber.js'
import { useToggleAccountDrawer } from 'components/AccountDrawer'
import AddressInputPanel from 'components/AddressInputPanel'
import { ButtonError, ButtonLight, ButtonPrimary } from 'components/Button'
import { GrayCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import SwapCurrencyInputPanel from 'components/CurrencyInputPanel/SwapCurrencyInputPanel'
import Img from 'components/Img'
import { NetworkAlert } from 'components/NetworkAlert/NetworkAlert'
import { AutoRow } from 'components/Row'
import ConfirmSwapModal from 'components/swap/ConfirmSwapModal'
import { LiquidityHub, useBestTradeLH } from 'components/swap/LiquidityHub'
import PriceImpactModal from 'components/swap/PriceImpactModal'
import PriceImpactWarning from 'components/swap/PriceImpactWarning'
import { ArrowWrapper, PageWrapper, SwapWrapper } from 'components/swap/styled'
import SwapDetailsDropdown from 'components/swap/SwapDetailsDropdown'
import SwapHeader from 'components/swap/SwapHeader'
import TradePrice from 'components/swap/TradePrice'
import { SwitchLocaleLink } from 'components/SwitchLocaleLink'
import TokenSafetyModal from 'components/TokenSafety/TokenSafetyModal'
import { PARASWAP_PROXY_ROUTER_ADDRESS } from 'constants/addresses'
import { getChainInfo } from 'constants/chainInfo'
import { asSupportedChain, BestSwapNewChains, isSupportedChain } from 'constants/chains'
import { BIG_INT_ZERO, PARASWAP_PARTNER_ID, PARASWAP_SUPPORTED_CHAINS, ZERO_PERCENT } from 'constants/misc'
import { getSwapCurrencyId, TOKEN_SHORTHANDS } from 'constants/tokens'
import { useFotAdjustmentsEnabled } from 'featureFlags/flags/fotAdjustments'
import { useCurrency, useDefaultActiveTokens } from 'hooks/Tokens'
import { useIsSwapUnsupported } from 'hooks/useIsSwapUnsupported'
import { useLocalCurrencyPrice } from 'hooks/useLocalCurrencyPrice'
import { useRateMaxAmountIn } from 'hooks/useMaxAmountIn'
import { getBestTradeCurrencyAddress, useParaswap } from 'hooks/useParaswap'
import usePermit2Allowance, { AllowanceState, useContractAllowance } from 'hooks/usePermit2Allowance'
import usePrevious from 'hooks/usePrevious'
import { SwapResult, useSwapCallback } from 'hooks/useSwapCallback'
import { useSwapTaxes } from 'hooks/useSwapTaxes'
import { useSwitchChain } from 'hooks/useSwitchChain'
import useWrapCallback, { WrapErrorText, WrapType } from 'hooks/useWrapCallback'
import JSBI from 'jsbi'
import { formatSwapQuoteReceivedEventProperties } from 'lib/utils/analytics'
import { ReactNode, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { ArrowDown } from 'react-feather'
import { useQuery } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { Text } from 'rebass'
import { useAppSelector } from 'state/hooks'
import {
  ClassicTrade,
  IHasQuoteProperties,
  IHasTradeProperties,
  QuoteMethod,
  TradeFillType,
  TradeState,
} from 'state/routing/types'
import { isClassicTrade, isQuoteClassicTrade, isQuoteLiquidityHub, isQuoteOptimalRate } from 'state/routing/utils'
import { Field, forceExactInput, replaceSwapState } from 'state/swap/actions'
import { useDefaultsFromURLSearch, useDerivedSwapInfo, useSwapActionHandlers } from 'state/swap/hooks'
import swapReducer, { initialState as initialSwapState, SwapState } from 'state/swap/reducer'
import styled, { useTheme } from 'styled-components'
import { CloseIcon, LinkStyledButton, ThemedText } from 'theme'
import { maybeLogFirstSwapAction } from 'tracing/swapFlowLoggers'
import { rateMinimumAmountOut } from 'utils/calculateSlippageAmount'
import { computeFiatValuePriceImpact } from 'utils/computeFiatValuePriceImpact'
import { getChainFromUrl } from 'utils/dynamicSwapRoute'
import { formatCurrencyAmount, NumberType } from 'utils/formatNumbers'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import { computeRealizedPriceImpact, warningSeverity } from 'utils/prices'
import { didUserReject } from 'utils/swapErrorToUserReadableMessage'
import { isMobile } from 'utils/userAgent'

import { useIsDarkMode } from '../../theme/components/ThemeToggle'
import { OutputTaxTooltipBody } from './TaxTooltipBody'
const ArrowContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`

const SwapContainer = styled.div`
  border: 2px solid ${({ theme }) => theme.accent1};
  border-radius: 20px;
  padding: 24px;
`

const SwapSection = styled.div`
  background-color: ${({ theme }) => theme.surface2};
  border-radius: 16px;
  color: ${({ theme }) => theme.neutral2};
  font-size: 14px;
  font-weight: 500;
  height: 120px;
  line-height: 20px;
  margin: 20px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding: 16px;
  position: relative;

  &:before {
    box-sizing: border-box;
    background-size: 100%;
    border-radius: inherit;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    pointer-events: none;
    content: '';
    border: 1px solid ${({ theme }) => theme.surface2};
  }

  &:hover:before {
    border-color: ${({ theme }) => theme.deprecated_stateOverlayHover};
  }

  &:focus-within:before {
    border-color: ${({ theme }) => theme.deprecated_stateOverlayPressed};
  }
` // Style the popup
const FiatPopupContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #162545;
  color: white;
  padding: 18px 10px; /* Increased padding */
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    right: 0px;
    bottom: 40px;
  }
`

const FiatPopupContent = styled.div`
  display: flex;
  align-items: flex-start; // Align items to the top
  flex-direction: row; // Stack content vertically
  max-width: 80%;
`

const FiatPopupIcon = styled.div`
  margin-right: 16px; /* Increased margin */
  width: 120px;

  img {
    width: 100%;
    height: auto;
  }
`

const PopupText = styled.span`
  font-size: 16px; /* Increased font size */
  font-weight: 500;
`

const PoweredBy = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #888; /* Light gray text */
`

const MeldIconContainer = styled.div`
  width: 32px;
  margin-left: 4px;

  img {
    width: 100%;
    height: auto;
  }
`

const BuyWithFiatLink = styled.span`
  font-size: 14px;
  color: #4299e1; /* Light blue link color */
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  svg {
    margin-left: 4px;
  }
`

const CatImageContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 200px; /* Increased image size */
  height: 200px;

  img {
    width: 100%;
    height: auto;
    opacity: 0.2;
  }
`
const ButtonSwapContainer = styled.div`
  margin: 24px;
`

const CatContainer = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: ${({ gap }) => gap ?? 0};

  ${({ theme }) => theme.breakpoint.md} {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
`
const MeldIframeContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent background
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1021; // Ensure iframe is above other elements
`

const MeldIframeCloseButton = styled(CloseIcon)`
  position: absolute;
  top: 25px;
  right: 10px;
  color: white;
  z-index: 10000; // Ensure button is above iframe
  cursor: pointer;
`

const OutputSwapSection = styled(SwapSection)`
  border-bottom: ${({ theme }) => `1px solid ${theme.surface1}`};
`

const ImageWrapper = styled.div`
  @media only screen and (max-width: 945px) {
    display: none;
  }
`

function getIsValidSwapQuote(
  wrappedTrade: IHasTradeProperties | undefined,
  tradeState: TradeState,
  swapInputError?: ReactNode
): boolean {
  return Boolean(
    !swapInputError &&
      wrappedTrade &&
      (isQuoteOptimalRate(wrappedTrade.quote) ||
        isQuoteLiquidityHub(wrappedTrade.quote) ||
        tradeState == TradeState.VALID)
  )
}

function largerPercentValue(a?: Percent, b?: Percent) {
  if (a && b) {
    return a.greaterThan(b) ? a : b
  } else if (a) {
    return a
  } else if (b) {
    return b
  }
  return undefined
}

export default function BestSwapPage({ className }: { className?: string }) {
  const { chainId: connectedChainId } = useWeb3React()
  const loadedUrlParams = useDefaultsFromURLSearch()

  const [showFiatPopup, setShowFiatPopup] = useState(true)
  const [showMeldIframe, setShowMeldIframe] = useState(false)

  const handleFiatPopupClick = () => {
    setShowMeldIframe(true)
  }
  const meldIcon = 'https://quickswap.exchange/static/media/meldIcon.d28d7bf528e28110c87a.webp'
  const location = useLocation()
  const offChain = getChainFromUrl(location)
  // const supportedChainId = asSupportedChain(connectedChainId)
  // Currently we only support fantom for best swap
  const supportedChainId = [
    ChainId.FANTOM,
    ChainId.EON,
    ChainId.BIT_TORRENT_MAINNET,
    ChainId.BERA_TESTNET,
    ...BestSwapNewChains,
  ].includes(connectedChainId as ChainId)
    ? connectedChainId
    : ChainId.FANTOM

  return (
    <Trace page={InterfacePageName.SWAP_PAGE} shouldLogImpression>
      <CatContainer gap="12px">
        <ImageWrapper>
          {!isMobile && <Img alt="mirrorGrim" src="https://assets.spooky.fi/mirror_grim.png" width={280} />}
        </ImageWrapper>
        <PageWrapper>
          <BestSwapInnerContent
            className={className}
            chainId={supportedChainId ?? offChain}
            prefilledState={{
              [Field.INPUT]: { currencyId: loadedUrlParams?.[Field.INPUT]?.currencyId },
              [Field.OUTPUT]: { currencyId: loadedUrlParams?.[Field.OUTPUT]?.currencyId },
            }}
            disableTokenInputs={supportedChainId !== undefined && supportedChainId !== connectedChainId}
          />
          <NetworkAlert />
        </PageWrapper>
        <ImageWrapper>
          {!isMobile && <Img alt="mirrorAce" src="https://assets.spooky.fi/mirror_ace.png" width={280} />}
        </ImageWrapper>
      </CatContainer>
      {location.pathname === '/swap' && <SwitchLocaleLink />}
      {showMeldIframe && (
        <MeldIframeContainer onClick={() => setShowMeldIframe(false)}>
          <MeldIframeCloseButton size={18} fontWeight={700} onClick={() => setShowMeldIframe(false)} />
          <iframe
            src="https://meldcrypto.com/?publicKey=WXDsAnXZ8t7Nu9QB5kPJS5:7sjEWU1mp48FTCYJEnro6T1fwVHEU&destinationCurrencyCode=FTM"
            width="470px"
            height="95%"
            style={{ borderRadius: '8px' }}
          />
        </MeldIframeContainer>
      )}
      {showFiatPopup && (
        <FiatPopupContainer>
          <FiatPopupContent onClick={handleFiatPopupClick}>
            <FiatPopupIcon>
              <img src="https://i.imgur.com/dmHOoD6.png" alt="Credit Card" />
            </FiatPopupIcon>
            <div>
              <PopupText>
                <Trans>Instantly Buy & Sell Crypto with Fiat!</Trans>
              </PopupText>
              <PoweredBy>
                Powered by
                <MeldIconContainer>
                  <img src={meldIcon} alt="Meld Icon" />
                </MeldIconContainer>
              </PoweredBy>

              <BuyWithFiatLink>Buy with Fiat</BuyWithFiatLink>
            </div>
          </FiatPopupContent>

          <CloseIcon
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              color: 'white',
              zIndex: 1090,
            }}
            size={18}
            fontWeight={700}
            z={1090}
            onClick={() => setShowFiatPopup(false)}
          />
          <CatImageContainer>
            <img src="https://assets.spooky.fi/mirror_grim.png" alt="Cat" />
          </CatImageContainer>
        </FiatPopupContainer>
      )}
    </Trace>
  )
}

/**
 * The swap component displays the swap interface, manages state for the swap, and triggers onchain swaps.
 *
 * In most cases, chainId should refer to the connected chain, i.e. `useWeb3React().chainId`.
 * However if this component is being used in a context that displays information from a different, unconnected
 * chain (e.g. the TDP), then chainId should refer to the unconnected chain.
 */
// eslint-disable-next-line import/no-unused-modules
function BestSwapContent({
  className,
  prefilledState = {},
  chainId,
  onCurrencyChange,
  disableTokenInputs = false,
}: {
  className?: string
  prefilledState?: Partial<SwapState>
  chainId?: ChainId
  onCurrencyChange?: (selected: Pick<SwapState, Field.INPUT | Field.OUTPUT>) => void
  disableTokenInputs?: boolean
}) {
  //TODO: this should be based on the users configurations and they should be allowed to override it to 100%
  const ONE = useMemo(() => new Percent(1, 1), [])

  const { account, chainId: connectedChainId, connector } = useWeb3React()
  const trace = useTrace()

  // token warning stuff
  const prefilledInputCurrency = useCurrency(prefilledState?.[Field.INPUT]?.currencyId)
  const prefilledOutputCurrency = useCurrency(prefilledState?.[Field.OUTPUT]?.currencyId)

  const [loadedInputCurrency, setLoadedInputCurrency] = useState(prefilledInputCurrency)
  const [loadedOutputCurrency, setLoadedOutputCurrency] = useState(prefilledOutputCurrency)

  useEffect(() => {
    setLoadedInputCurrency(prefilledInputCurrency)
    setLoadedOutputCurrency(prefilledOutputCurrency)
  }, [prefilledInputCurrency, prefilledOutputCurrency])

  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const [showPriceImpactModal, setShowPriceImpactModal] = useState<boolean>(false)

  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c?.isToken ?? false) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useDefaultActiveTokens(chainId)
  const importTokensNotInDefault = useMemo(
    () =>
      urlLoadedTokens &&
      urlLoadedTokens
        .filter((token: Token) => {
          return !(token.address in defaultTokens)
        })
        .filter((token: Token) => {
          // Any token addresses that are loaded from the shorthands map do not need to show the import URL
          const supported = asSupportedChain(chainId)
          if (!supported) return true
          return !Object.keys(TOKEN_SHORTHANDS).some((shorthand) => {
            const shorthandTokenAddress = TOKEN_SHORTHANDS[shorthand][supported]
            return shorthandTokenAddress && shorthandTokenAddress === token.address
          })
        }),
    [chainId, defaultTokens, urlLoadedTokens]
  )

  const theme = useTheme()

  // toggle wallet when disconnected
  const toggleWalletDrawer = useToggleAccountDrawer()

  // swap state
  const [state, dispatch] = useReducer(swapReducer, { ...initialSwapState, ...prefilledState })
  const { typedValue, recipient, independentField } = state

  const previousConnectedChainId = usePrevious(connectedChainId)
  const previousPrefilledState = usePrevious(prefilledState)
  useEffect(() => {
    const combinedInitialState = { ...initialSwapState, ...prefilledState }
    const chainChanged = previousConnectedChainId && previousConnectedChainId !== connectedChainId
    const prefilledInputChanged =
      previousPrefilledState &&
      previousPrefilledState?.[Field.INPUT]?.currencyId !== prefilledState?.[Field.INPUT]?.currencyId
    const prefilledOutputChanged =
      previousPrefilledState &&
      previousPrefilledState?.[Field.OUTPUT]?.currencyId !== prefilledState?.[Field.OUTPUT]?.currencyId
    if (chainChanged || prefilledInputChanged || prefilledOutputChanged) {
      dispatch(
        replaceSwapState({
          ...initialSwapState,
          ...prefilledState,
          field: combinedInitialState.independentField ?? Field.INPUT,
          inputCurrencyId: combinedInitialState.INPUT.currencyId ?? undefined,
          outputCurrencyId: combinedInitialState.OUTPUT.currencyId ?? undefined,
        })
      )
      // reset local state
      setSwapState({
        tradeToConfirm: undefined,
        swapError: undefined,
        showConfirm: false,
        swapResult: undefined,
      })
    }
  }, [connectedChainId, prefilledState, previousConnectedChainId, previousPrefilledState])

  const swapInfo = useDerivedSwapInfo(state, chainId)
  const {
    trade: { state: tradeState, trade, swapQuoteLatency },
    allowedSlippage,
    autoSlippage,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = swapInfo

  const swapType = useMemo(() => {
    return independentField === Field.INPUT ? SwapSide.SELL : SwapSide.BUY
  }, [independentField])

  const inputCurrency = currencies[Field.INPUT] ?? undefined
  const outputCurrency = currencies[Field.OUTPUT] ?? undefined

  const fotAdjustmentsEnabled = useFotAdjustmentsEnabled()
  const { inputTax, outputTax } = useSwapTaxes(
    inputCurrency?.isToken && fotAdjustmentsEnabled ? inputCurrency.address : undefined,
    outputCurrency?.isToken && fotAdjustmentsEnabled ? outputCurrency.address : undefined
  )

  const [inputTokenHasTax, outputTokenHasTax] = useMemo(
    () => [!inputTax.equalTo(0), !outputTax.equalTo(0)],
    [inputTax, outputTax]
  )

  const paraswap = useParaswap()

  const srcToken = inputCurrency ? getBestTradeCurrencyAddress(inputCurrency) : undefined
  const destToken = outputCurrency ? getBestTradeCurrencyAddress(outputCurrency) : undefined

  const srcDecimals = inputCurrency?.decimals
  const destDecimals = outputCurrency?.decimals
  const tradeDecimals = swapType === SwapSide.SELL ? srcDecimals : destDecimals

  const srcAmount =
    parsedAmount && tradeDecimals ? parsedAmount.multiply(JSBI.BigInt(10 ** tradeDecimals)).toFixed(0) : undefined

  const fetchOptimalRate = async () => {
    if (!srcToken || !destToken || !srcAmount || !paraswap) {
      return null
    }
    try {
      const rate = await paraswap.swap.getRate({
        srcToken,
        destToken,
        srcDecimals,
        destDecimals,
        amount: srcAmount,
        side: swapType,
        options: {
          includeDEXS: ['SpookySwap', 'SpookySwapV3'],
          maxImpact: 100, // We hardcode the max impact to 100 as impact is verifed on our end with the price impact modal
          partner: PARASWAP_PARTNER_ID,
          srcTokenTransferFee: inputTax ? Number(inputTax.toSignificant()) * 100 : undefined,
          destTokenTransferFee: outputTax ? Number(outputTax.toSignificant()) * 100 : undefined,
          //ignoreBadUsdPrice: true,
        },
      })

      return { error: undefined, rate }
    } catch (err) {
      return { error: err.message, rate: undefined }
    }
  }

  const { isLoading: loadingOptimalRate, data: optimalRateData } = useQuery({
    queryKey: ['fetchOptimalRate', srcToken, destToken, srcAmount, swapType],
    queryFn: fetchOptimalRate,
    refetchInterval: 5000,
    enabled: !!(srcToken && destToken && srcAmount && paraswap && chainId == ChainId.FANTOM),
  })

  const addZerosToEnd = useCallback((str: string, numOfZeros: number) => {
    return str + '0'.repeat(numOfZeros)
  }, [])

  const normalizePricesForPercent = useCallback(
    (srcValue: string, dstValue: string) => {
      const srcDecimals = srcValue.split('.')[1]?.length || 0
      const destDecimals = dstValue.split('.')[1]?.length || 0
      const maxDecimalPlaces = Math.max(srcDecimals, destDecimals)

      if (maxDecimalPlaces == srcDecimals) {
        return {
          normalizedSrcUSD: srcValue.replace('.', ''),
          normalizedDestUSD: addZerosToEnd(dstValue.replace('.', ''), srcDecimals - destDecimals),
        }
      }

      return {
        normalizedSrcUSD: addZerosToEnd(srcValue.replace('.', ''), destDecimals - srcDecimals),
        normalizedDestUSD: dstValue.replace('.', ''),
      }
    },
    [addZerosToEnd]
  )

  //This is not needed currently however we will use this function just to encapsulate the logic
  const resolveQuoteFromTrade = useCallback((trade: ClassicTrade): IHasQuoteProperties => {
    //We need to manually adapt since this ts doesn't like getters
    return {
      inputAmount: trade.inputAmount,
      outputAmount: trade.outputAmount,
      executionPrice: trade.executionPrice,
      quote: trade,
      quoteMethod: trade.quoteMethod,
      fillType: trade.fillType,
      tradeType: trade.tradeType,
      postTaxOutputAmount: trade.postTaxOutputAmount,
      approveInfo: trade.approveInfo,
      gasUseEstimateUSD: trade.gasUseEstimateUSD,
      totalGasUseEstimateUSD: trade.totalGasUseEstimateUSD,
    }
  }, [])

  const resolveQuoteFromOptimalRate = useCallback(
    (rate: OptimalRate, inputCurrency: Currency, outputCurrency: Currency): IHasQuoteProperties => {
      const rateInputAmount = CurrencyAmount.fromRawAmount(inputCurrency, rate.srcAmount)
      const rateOutputAmount = CurrencyAmount.fromRawAmount(outputCurrency, rate.destAmount)

      return {
        inputAmount: rateInputAmount,
        outputAmount: rateOutputAmount,
        executionPrice: new Price(
          rateInputAmount.currency,
          rateOutputAmount.currency,
          rateInputAmount.quotient,
          rateOutputAmount.quotient
        ),
        quote: rate,
        quoteMethod: QuoteMethod.BEST_TRADE_API,
        fillType: TradeFillType.BestSwap,
        tradeType: rate.side === SwapSide.SELL ? TradeType.EXACT_INPUT : TradeType.EXACT_OUTPUT,
        postTaxOutputAmount: rateOutputAmount,
        // TODO: consider using permit2 with
        // TODO: research why this is always false, if we need to approve a different contract shouldn't we be able to do so
        approveInfo: { needsApprove: false },
        gasUseEstimateUSD: Number(rate.gasCostUSD),
      }
    },
    [] // Dependency array can be adjusted if needed
  )

  const resolveQuoteFromLiquidityHub = useCallback(
    (quoteResponse: QuoteResponse, inputCurrency: Currency, outputCurrency: Currency): IHasQuoteProperties => {
      // Another shitty hack cuz of liquidity hub,
      const rateInputAmount = CurrencyAmount.fromRawAmount(inputCurrency, srcAmount ?? '0')
      const rateOutputAmount = CurrencyAmount.fromRawAmount(outputCurrency, quoteResponse.minAmountOut ?? '0')

      return {
        inputAmount: rateInputAmount,
        outputAmount: rateOutputAmount,
        executionPrice: new Price(
          rateInputAmount.currency,
          rateOutputAmount.currency,
          rateInputAmount.quotient,
          rateOutputAmount.quotient
        ),
        quote: quoteResponse,
        quoteMethod: QuoteMethod.LIQUIDITY_HUB,
        fillType: TradeFillType.BestSwap,
        // Liquidty Hub only supports exact input
        tradeType: TradeType.EXACT_INPUT,
        postTaxOutputAmount: rateOutputAmount,
        // TODO: consider using permit2 with
        approveInfo: { needsApprove: false },
      }
    },
    [srcAmount] // Dependency array can be adjusted if needed
  )

  //TODO: fix trade to verify syncing
  const isTradeBetterThanRate = useCallback(
    (trade: ClassicTrade, rate: OptimalRate, inputCurrency: Currency, outputCurrency: Currency) => {
      const rateInputAmount = CurrencyAmount.fromRawAmount(inputCurrency, rate.srcAmount)
      const rateOutputAmount = CurrencyAmount.fromRawAmount(outputCurrency, rate.destAmount)
      if (trade.tradeType == TradeType.EXACT_INPUT) {
        return trade.minimumAmountOut(allowedSlippage).greaterThan(rateOutputAmount)
      }
      // If the trade is exact output that means the better trade will provide the least amount of input
      return trade.inputAmount.lessThan(rateInputAmount)
    },
    [allowedSlippage]
  )

  const liquidityHub = useBestTradeLH({
    allowedSlippage,
    independentField,
    inputCurrency: currencies.INPUT,
    outputCurrency: currencies.OUTPUT,
    inputAmount: srcAmount,
  })

  const liquidityHubQuote = liquidityHub.useQuote()

  const [isTradeRouteNotFound, isTradeRouteIsLoading, isTradeRouteIsSyncing] = useMemo(
    () => [
      tradeState === TradeState.NO_ROUTE_FOUND,
      tradeState === TradeState.LOADING,
      tradeState === TradeState.LOADING && Boolean(trade),
    ],
    [tradeState, trade]
  )

  //TODO: this should be completely refactored so that its resolved from a configuration array
  // the struct should contain the following:  quoteData, resolveQuote, supportsTradeType
  const bestQuote: IHasQuoteProperties | undefined = useMemo(() => {
    if (!inputCurrency || !outputCurrency) {
      return
    }

    const isTradeValid = !!trade && !isTradeRouteNotFound && !isTradeRouteIsSyncing && !isTradeRouteIsLoading
    if (chainId != ChainId.FANTOM && !isTradeValid) {
      return
      //Currently We only support API Trading for Fantom
    } else if (chainId != ChainId.FANTOM && isTradeValid) {
      return resolveQuoteFromTrade(trade)
    }

    //LH hub doesn't support taxes, not does it support the exact amount as output (e.g variable input)
    const isLHSwapSupport = !inputTokenHasTax && !outputTokenHasTax && independentField === Field.INPUT
    // !A !B !C
    if (!isTradeValid && !optimalRateData?.rate && !liquidityHubQuote.data) {
      return
      //A !B !C
    } else if (isTradeValid && !optimalRateData?.rate && !liquidityHubQuote.data) {
      return resolveQuoteFromTrade(trade)
      //!A B !C
    } else if (!isTradeValid && optimalRateData?.rate && !liquidityHubQuote.data) {
      return resolveQuoteFromOptimalRate(optimalRateData.rate, inputCurrency, outputCurrency)
      //!A !B C
    } else if (!isTradeValid && !optimalRateData?.rate && liquidityHubQuote.data) {
      if (!isLHSwapSupport) {
        return
      }

      return resolveQuoteFromLiquidityHub(liquidityHubQuote.data, inputCurrency, outputCurrency)

      // TODO: Figure out what to do when there is only an LH hub trade but no quote from paraswap
      //A B !C
    } else if (isTradeValid && optimalRateData?.rate && !liquidityHubQuote.data) {
      return isTradeBetterThanRate(trade, optimalRateData.rate, inputCurrency, outputCurrency)
        ? resolveQuoteFromTrade(trade)
        : resolveQuoteFromOptimalRate(optimalRateData.rate, inputCurrency, outputCurrency)
      //A !B C
    } else if (isTradeValid && !optimalRateData?.rate && liquidityHubQuote.data) {
      const lhHubMinAmountOut = CurrencyAmount.fromRawAmount(outputCurrency, liquidityHubQuote.data.minAmountOut || '0')
      return isLHSwapSupport && trade.minimumAmountOut(allowedSlippage).lessThan(lhHubMinAmountOut)
        ? resolveQuoteFromLiquidityHub(liquidityHubQuote.data, inputCurrency, outputCurrency)
        : resolveQuoteFromTrade(trade)

      // TODO: Resolve From Trade And LH Hub Note currently fiat values are based on optimal rate
      //!A B C
    } else if (!isTradeValid && optimalRateData?.rate && liquidityHubQuote.data) {
      const rateAmountOut = CurrencyAmount.fromRawAmount(outputCurrency, optimalRateData.rate.destAmount)
      const rateMinAmountOut = rateMinimumAmountOut(
        { tradeType: independentField === Field.INPUT ? TradeType.EXACT_INPUT : TradeType.EXACT_OUTPUT },
        allowedSlippage,
        rateAmountOut
      )
      const lhHubMinAmountOut = CurrencyAmount.fromRawAmount(outputCurrency, liquidityHubQuote.data.minAmountOut || '0')
      return isLHSwapSupport && rateMinAmountOut.lessThan(lhHubMinAmountOut)
        ? resolveQuoteFromLiquidityHub(liquidityHubQuote.data, inputCurrency, outputCurrency)
        : resolveQuoteFromOptimalRate(optimalRateData.rate, inputCurrency, outputCurrency)
    }

    //For some reason the compiler doesn't resolve this so we explicitly do it
    if (!isTradeValid || !optimalRateData?.rate || !liquidityHubQuote.data) {
      return
    }

    const rateAmountOut = CurrencyAmount.fromRawAmount(outputCurrency, optimalRateData.rate.destAmount)
    const rateMinAmountOut = rateMinimumAmountOut({ tradeType: trade.tradeType }, allowedSlippage, rateAmountOut)
    const lhHubMinAmountOut = CurrencyAmount.fromRawAmount(outputCurrency, liquidityHubQuote.data.minAmountOut || '0')
    // .quotient
    //A B C
    // Execute ABC
    return isTradeBetterThanRate(trade, optimalRateData.rate, inputCurrency, outputCurrency)
      ? isLHSwapSupport && trade.minimumAmountOut(allowedSlippage).lessThan(lhHubMinAmountOut)
        ? resolveQuoteFromLiquidityHub(liquidityHubQuote.data, inputCurrency, outputCurrency)
        : resolveQuoteFromTrade(trade)
      : isLHSwapSupport && rateMinAmountOut.lessThan(lhHubMinAmountOut)
      ? resolveQuoteFromLiquidityHub(liquidityHubQuote.data, inputCurrency, outputCurrency)
      : resolveQuoteFromOptimalRate(optimalRateData.rate, inputCurrency, outputCurrency)
  }, [
    inputCurrency,
    outputCurrency,
    trade,
    isTradeRouteNotFound,
    isTradeRouteIsSyncing,
    isTradeRouteIsLoading,
    inputTokenHasTax,
    outputTokenHasTax,
    independentField,
    optimalRateData?.rate,
    liquidityHubQuote.data,
    allowedSlippage,
    isTradeBetterThanRate,
    resolveQuoteFromLiquidityHub,
    resolveQuoteFromTrade,
    resolveQuoteFromOptimalRate,
  ])

  const isLHSwap = isQuoteLiquidityHub(bestQuote?.quote)

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE

  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
            [Field.INPUT]: parsedAmount,
            [Field.OUTPUT]: parsedAmount,
          }
        : {
            [Field.INPUT]:
              independentField === Field.INPUT
                ? parsedAmount
                : bestQuote && bestQuote.quote
                ? bestQuote.inputAmount
                : undefined,
            [Field.OUTPUT]:
              independentField === Field.OUTPUT
                ? parsedAmount
                : bestQuote && bestQuote.quote
                ? bestQuote.outputAmount
                : undefined,
          },
    [showWrap, parsedAmount, independentField, bestQuote]
  )

  const isTokenInputMulti = currencies[Field.INPUT]?.symbol?.includes('_MULTI') || false
  const isTokenOutputMulti = currencies[Field.OUTPUT]?.symbol?.includes('_MULTI') || false
  const isSwapWithNoTokenMulti = !isTokenInputMulti && !isTokenOutputMulti

  const showFiatValueInput = Boolean(parsedAmounts[Field.INPUT]) && isSwapWithNoTokenMulti
  const showFiatValueOutput =
    Boolean(isQuoteOptimalRate(bestQuote?.quote) ? bestQuote?.quote?.destAmount : parsedAmounts[Field.OUTPUT]) &&
    isSwapWithNoTokenMulti

  const tradeFiatValueInput = useLocalCurrencyPrice(parsedAmounts[Field.INPUT], currencies[Field.INPUT] ?? undefined)
  const tradeFiatValueOutput = useLocalCurrencyPrice(parsedAmounts[Field.OUTPUT], currencies[Field.OUTPUT] ?? undefined)
  const fiatValueTradeOutput = useLocalCurrencyPrice(trade?.postTaxOutputAmount)

  const [routeNotFound, routeIsLoading, routeIsSyncing] = useMemo(() => {
    if (!bestQuote?.quote) {
      return [false, true, true]
    }
    return isQuoteClassicTrade(bestQuote?.quote)
      ? [
          tradeState === TradeState.NO_ROUTE_FOUND,
          tradeState === TradeState.LOADING,
          tradeState === TradeState.LOADING && Boolean(trade),
        ]
      : // if we have a quote back then there is a route, and its done loading
        [false, false, false]
  }, [trade, tradeState, bestQuote?.quote])

  const fiatValueInput = useMemo(() => {
    if (!bestQuote?.quote && (loadingOptimalRate || routeIsLoading || liquidityHub.quoteLoading)) {
      return { data: undefined, isLoading: true }
    } else if (!bestQuote?.quote) {
      return { data: undefined, isLoading: false }
      //TODO: Liquidity hub does not have a method to resolve usd so if we cannot resolve usdc price from optimal rate use tradeFiatValue
    } else if (isQuoteClassicTrade(bestQuote.quote) || !optimalRateData?.rate) {
      return tradeFiatValueInput
    }

    return { data: Number(optimalRateData.rate.srcUSD), isLoading: false }
  }, [
    bestQuote?.quote,
    liquidityHub.quoteLoading,
    loadingOptimalRate,
    optimalRateData?.rate,
    routeIsLoading,
    tradeFiatValueInput,
  ])

  //TODO: we need to fix this, since lhhub relies on bestQuote, even if trade is better if lh
  const fiatValueOutput: { data?: number; rawData?: string; isLoading: boolean } = useMemo(() => {
    if (!bestQuote?.quote && (loadingOptimalRate || routeIsLoading || liquidityHub.quoteLoading)) {
      return { data: undefined, rawData: undefined, isLoading: true }
    } else if (!bestQuote?.quote) {
      return { data: undefined, rawData: undefined, isLoading: false }
    }
    if (isQuoteClassicTrade(bestQuote.quote)) {
      return tradeFiatValueOutput
    }
    if (isQuoteOptimalRate(bestQuote.quote)) {
      return { data: Number(bestQuote.quote.destUSD), rawData: bestQuote.quote.destUSD, isLoading: false }
    }

    // TODO: orbs doesn't have their own function to resolve the fiat amount so we must rely on paraswaps
    if (!optimalRateData?.rate) {
      return { data: undefined, rawData: undefined, isLoading: false }
    }

    if (
      !liquidityHub.quote?.outAmount ||
      BN(liquidityHub.quote?.outAmount || '0').isZero() ||
      //Avoid divide by zero
      BN(optimalRateData.rate.destAmount).isZero()
    ) {
      return { data: undefined, rawData: undefined, isLoading: false }
    }

    // if liquidity hub take the trade, we calculate the price impact based on the outAmount
    const dstUsd = BN(optimalRateData.rate.destUSD)
      // Multiply before dividing, as dividing a small number by an extremely large one can shrink the decimals to the point where
      // the decimal value is unusable
      .multipliedBy(liquidityHub.quote.outAmount)
      .dividedBy(optimalRateData.rate.destAmount)
      .toFixed(10)

    return { data: Number(dstUsd), rawData: dstUsd, isLoading: false }
  }, [
    bestQuote?.quote,
    loadingOptimalRate,
    routeIsLoading,
    liquidityHub.quoteLoading,
    liquidityHub.quote?.outAmount,
    optimalRateData?.rate,
    tradeFiatValueOutput,
  ])

  const [stablecoinPriceImpact, preTaxStablecoinPriceImpact] = useMemo(
    () =>
      routeIsSyncing || !isClassicTrade(trade)
        ? [undefined, undefined]
        : [
            computeFiatValuePriceImpact(tradeFiatValueInput.data, fiatValueTradeOutput.data),
            computeFiatValuePriceImpact(tradeFiatValueInput.data, tradeFiatValueOutput.data),
          ],
    [fiatValueTradeOutput.data, tradeFiatValueOutput.data, routeIsSyncing, trade, tradeFiatValueInput.data]
  )

  const priceImpact = useMemo(() => {
    if (!bestQuote || !bestQuote.quote || !fiatValueOutput.rawData) {
      return undefined
    }
    if (isQuoteClassicTrade(bestQuote.quote)) {
      const marketPriceImpact = computeRealizedPriceImpact(bestQuote.quote)
      const largerPriceImpact = largerPercentValue(marketPriceImpact, preTaxStablecoinPriceImpact)
      return largerPriceImpact
    }

    //TODO: OptimalRate Data is used to calculate usd price since LiquidityHub cannot do so
    if (!optimalRateData?.rate) {
      return undefined
    }

    const prices = normalizePricesForPercent(optimalRateData.rate.srcUSD, fiatValueOutput.rawData)
    return ONE.subtract(new Percent(prices.normalizedDestUSD, prices.normalizedSrcUSD))
  }, [
    ONE,
    bestQuote,
    fiatValueOutput.rawData,
    normalizePricesForPercent,
    optimalRateData?.rate,
    preTaxStablecoinPriceImpact,
  ])

  // warnings on the greater of fiat value price impact and execution price impact
  const { priceImpactSeverity, largerPriceImpact } = useMemo(() => {
    if (isLHSwap && !priceImpact && !BN(liquidityHub.quote?.outAmount || '0').isZero()) {
      return { priceImpactSeverity: 4, largerPriceImpact: undefined }
    }

    return { priceImpactSeverity: warningSeverity(priceImpact), largerPriceImpact: priceImpact }
  }, [isLHSwap, liquidityHub.quote?.outAmount, priceImpact])

  const wrappedTrade: IHasTradeProperties | undefined = useMemo(() => {
    if (!bestQuote) return

    const formattedTradeProperties: IHasTradeProperties = {
      ...bestQuote,
      // Token Tax is calculated and already is factored into the amount out,
      // we're passing the same value so the architecture can remain congruent with the trade interface
      totalTaxRate: ZERO_PERCENT,
      //TODO: We need to change the fill type but the result relies on classic,
      inputTax,
      outputTax,
      priceImpact,
      // TODO: Consider removing this as it was only used for uniswapX
      // wrapInfo: { needsWrap: false },
      //totalGasUseEstimateUSD?: number
    }

    return formattedTradeProperties
  }, [bestQuote, inputTax, outputTax, priceImpact])

  useEffect(() => {
    // Force exact input if the user switches to an output token with tax
    if (outputTokenHasTax && independentField === Field.OUTPUT) {
      dispatch(forceExactInput())
    }
  }, [independentField, outputTokenHasTax, bestQuote?.outputAmount])

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers(dispatch)
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
      maybeLogFirstSwapAction(trace)
    },
    [onUserInput, trace]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
      maybeLogFirstSwapAction(trace)
    },
    [onUserInput, trace]
  )

  const navigate = useNavigate()
  const swapIsUnsupported = useIsSwapUnsupported(currencies[Field.INPUT], currencies[Field.OUTPUT])

  // reset if they close warning without tokens in params
  const handleDismissTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
    navigate('/swap/')
  }, [navigate])

  // modal and loading
  const [{ showConfirm, tradeToConfirm: rateToConfirm, swapError, swapResult }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm?: IHasTradeProperties
    swapError?: Error
    swapResult?: SwapResult
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    swapError: undefined,
    swapResult: undefined,
  })

  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue,
      [dependentField]: showWrap
        ? parsedAmounts[independentField]?.toExact() ?? ''
        : formatCurrencyAmount(parsedAmounts[dependentField], NumberType.SwapTradeAmount, ''),
    }),
    [dependentField, independentField, parsedAmounts, showWrap, typedValue]
  )

  function isParaswapSupported(chainId: ChainId) {
    return PARASWAP_SUPPORTED_CHAINS.includes(chainId)
  }

  const maximumAmountIn = useRateMaxAmountIn(bestQuote, allowedSlippage)
  const rateAllowance = useContractAllowance(
    chainId && isParaswapSupported(chainId) ? PARASWAP_PROXY_ROUTER_ADDRESS[chainId] : undefined,
    maximumAmountIn ??
      (parsedAmounts[Field.INPUT]?.currency.isToken ? (parsedAmounts[Field.INPUT] as CurrencyAmount<Token>) : undefined)
  )

  const tradeAllowance = usePermit2Allowance(
    maximumAmountIn ??
      (parsedAmounts[Field.INPUT]?.currency.isToken
        ? (parsedAmounts[Field.INPUT] as CurrencyAmount<Token>)
        : undefined),
    isSupportedChain(chainId) ? UNIVERSAL_ROUTER_ADDRESS(chainId) : undefined,
    trade?.fillType
  )

  const allowance = isQuoteOptimalRate(bestQuote?.quote) ? rateAllowance : tradeAllowance

  const maxInputAmount: CurrencyAmount<Currency> | undefined = useMemo(
    () => maxAmountSpend(currencyBalances[Field.INPUT]),
    [currencyBalances]
  )
  const showMaxButton = Boolean(maxInputAmount?.greaterThan(0) && !parsedAmounts[Field.INPUT]?.equalTo(maxInputAmount))
  const handleContinueToReviewDex = useCallback(() => {
    setSwapState({
      tradeToConfirm: wrappedTrade,
      swapError: undefined,
      showConfirm: true,
      swapResult: undefined,
    })
  }, [wrappedTrade])

  const handleOnWrap = useCallback(async () => {
    if (!onWrap) return
    try {
      const txHash = await onWrap()
      setSwapState((currentState) => ({
        ...currentState,
        swapError: undefined,
        txHash,
      }))
      onUserInput(Field.INPUT, '')
    } catch (error) {
      if (!didUserReject(error)) {
        sendAnalyticsEvent(SwapEventName.SWAP_ERROR, {
          wrapType,
          input: currencies[Field.INPUT],
          output: currencies[Field.OUTPUT],
        })
      }
      console.error('Could not wrap/unwrap', error)
      setSwapState((currentState) => ({
        ...currentState,
        swapError: error,
        txHash: undefined,
      }))
    }
  }, [currencies, onUserInput, onWrap, wrapType])

  const handleConfirmDismiss = useCallback(() => {
    setSwapState((currentState) => ({ ...currentState, showConfirm: false }))
    // If there was a swap, we want to clear the input
    if (swapResult) {
      onUserInput(Field.INPUT, '')
    }
  }, [onUserInput, swapResult])

  const handleAcceptChanges = useCallback(() => {
    setSwapState((currentState) => ({ ...currentState, tradeToConfirm: wrappedTrade }))
  }, [wrappedTrade])

  const handleInputSelect = useCallback(
    (inputCurrency: Currency) => {
      onCurrencySelection(Field.INPUT, inputCurrency)
      onCurrencyChange?.({
        [Field.INPUT]: {
          currencyId: getSwapCurrencyId(inputCurrency),
        },
        [Field.OUTPUT]: state[Field.OUTPUT],
      })
      maybeLogFirstSwapAction(trace)
    },
    [onCurrencyChange, onCurrencySelection, state, trace]
  )
  const inputCurrencyNumericalInputRef = useRef<HTMLInputElement>(null)

  const handleMaxInput = useCallback(() => {
    maxInputAmount && onUserInput(Field.INPUT, maxInputAmount.toExact())
    maybeLogFirstSwapAction(trace)
  }, [maxInputAmount, onUserInput, trace])

  const handleOutputSelect = useCallback(
    (outputCurrency: Currency) => {
      onCurrencySelection(Field.OUTPUT, outputCurrency)
      onCurrencyChange?.({
        [Field.INPUT]: state[Field.INPUT],
        [Field.OUTPUT]: {
          currencyId: getSwapCurrencyId(outputCurrency),
        },
      })
      maybeLogFirstSwapAction(trace)
    },
    [onCurrencyChange, onCurrencySelection, state, trace]
  )
  const showPriceImpactWarning = priceImpactSeverity > 3 && isSwapWithNoTokenMulti

  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(BIG_INT_ZERO)
  )

  const prevTrade = usePrevious(trade)
  useEffect(() => {
    if (!trade || prevTrade === trade) return // no new swap quote to log

    sendAnalyticsEvent(SwapEventName.SWAP_QUOTE_RECEIVED, {
      ...formatSwapQuoteReceivedEventProperties(trade, allowedSlippage, swapQuoteLatency),
      ...trace,
    })
  }, [prevTrade, trade, trace, allowedSlippage, swapQuoteLatency])

  const showDetailsDropdown = Boolean(
    !showWrap && userHasSpecifiedInputOutput && (wrappedTrade || routeIsLoading || routeIsSyncing)
  )

  const swapFiatValues = useMemo(() => {
    return {
      amountIn: fiatValueInput.data,
      amountOut: isQuoteClassicTrade(bestQuote?.quote) ? fiatValueTradeOutput.data : fiatValueOutput.data,
    }
  }, [fiatValueInput.data, bestQuote?.quote, fiatValueTradeOutput.data, fiatValueOutput.data])

  const swapCallback = useSwapCallback(
    wrappedTrade,
    swapFiatValues,
    allowedSlippage,
    allowance.state === AllowanceState.ALLOWED ? allowance.permitSignature : undefined,
    recipient
  )

  const handleSwap = useCallback(() => {
    if (!swapCallback) {
      return
    }
    // TODO: add this logic back in for clientside swap etc
    // if (preTaxStablecoinPriceImpact && !confirmPriceImpactWithoutFee(preTaxStablecoinPriceImpact)) {
    //   return
    // }

    setSwapState((currentState) => ({
      ...currentState,
      swapError: undefined,
      swapResult: undefined,
    }))
    swapCallback()
      .then((result) => {
        setSwapState((currentState) => ({
          ...currentState,
          swapError: undefined,
          swapResult: result,
        }))
      })
      .catch((error) => {
        setSwapState((currentState) => ({
          ...currentState,
          swapError: error,
          swapResult: undefined,
        }))
      })
  }, [swapCallback])

  const switchChain = useSwitchChain()
  const switchingChain = useAppSelector((state) => state.wallets.switchingChain)
  const isDark = useIsDarkMode()

  const handleContinueToReview = useCallback(() => {
    liquidityHub.analyticsInit()
    if (isLHSwap) {
      liquidityHub.swap()
    } else {
      handleContinueToReviewDex()
    }
  }, [liquidityHub, isLHSwap, handleContinueToReviewDex])

  const isSwapButtonDisabled = useMemo(() => {
    const isValidSwapQuote = getIsValidSwapQuote(wrappedTrade, tradeState, swapInputError)
    if (swapInputError) return true
    if (isLHSwap && BN(liquidityHub.quote?.outAmount || '0').isZero()) return true
    if (!isValidSwapQuote) return true
    return false
  }, [wrappedTrade, tradeState, swapInputError, isLHSwap, liquidityHub.quote?.outAmount])

  const swapElement = (
    <SwapContainer>
      <SwapWrapper isDark={isDark} className={className} id="swap-page">
        <TokenSafetyModal
          isOpen={importTokensNotInDefault.length > 0 && !dismissTokenWarning}
          tokenAddress={importTokensNotInDefault[0]?.address}
          secondTokenAddress={importTokensNotInDefault[1]?.address}
          onContinue={handleConfirmTokenWarning}
          onCancel={handleDismissTokenWarning}
          showCancel={true}
        />
        <SwapHeader isUniXTrade={false} autoSlippage={autoSlippage} chainId={chainId} />
        {wrappedTrade && showConfirm && allowance.state !== AllowanceState.LOADING && (
          <ConfirmSwapModal
            trade={wrappedTrade}
            originalTrade={rateToConfirm}
            inputCurrency={inputCurrency}
            onAcceptChanges={handleAcceptChanges}
            onCurrencySelection={onCurrencySelection}
            swapResult={swapResult}
            allowedSlippage={allowedSlippage}
            onConfirm={handleSwap}
            allowance={allowance}
            swapError={swapError}
            onDismiss={handleConfirmDismiss}
            fiatValueInput={fiatValueInput}
            fiatValueOutput={fiatValueOutput}
          />
        )}
        {showPriceImpactModal && showPriceImpactWarning && (
          <PriceImpactModal
            priceImpact={largerPriceImpact}
            onDismiss={() => setShowPriceImpactModal(false)}
            onContinue={() => {
              setShowPriceImpactModal(false)
              handleContinueToReview()
            }}
          />
        )}

        <div style={{ display: 'relative' }}>
          <SwapSection>
            <Trace section={InterfaceSectionName.CURRENCY_INPUT_PANEL}>
              <SwapCurrencyInputPanel
                label={<Trans>You pay</Trans>}
                disabled={disableTokenInputs}
                value={formattedAmounts[Field.INPUT]}
                showMaxButton={showMaxButton}
                currency={currencies[Field.INPUT] ?? null}
                onUserInput={handleTypeInput}
                onMax={handleMaxInput}
                fiatValue={showFiatValueInput ? fiatValueInput : undefined}
                onCurrencySelect={handleInputSelect}
                otherCurrency={currencies[Field.OUTPUT]}
                showCommonBases
                id={InterfaceSectionName.CURRENCY_INPUT_PANEL}
                loading={independentField === Field.OUTPUT && routeIsSyncing}
                ref={inputCurrencyNumericalInputRef}
              />
            </Trace>
          </SwapSection>
          <ArrowWrapper clickable={isSupportedChain(chainId)}>
            <TraceEvent
              events={[BrowserEvent.onClick]}
              name={SwapEventName.SWAP_TOKENS_REVERSED}
              element={InterfaceElementName.SWAP_TOKENS_REVERSE_ARROW_BUTTON}
            >
              <ArrowContainer
                data-testid="swap-currency-button"
                onClick={() => {
                  if (disableTokenInputs) return
                  onSwitchTokens(inputTokenHasTax, formattedAmounts[dependentField])
                  maybeLogFirstSwapAction(trace)
                }}
                color={theme.neutral1}
              >
                <ArrowDown size="16" color={theme.neutral1} />
              </ArrowContainer>
            </TraceEvent>
          </ArrowWrapper>
        </div>
        <AutoColumn gap="xs">
          <div>
            <OutputSwapSection>
              <Trace section={InterfaceSectionName.CURRENCY_OUTPUT_PANEL}>
                <SwapCurrencyInputPanel
                  value={isLHSwap ? liquidityHub.quote?.ui.outAmount || '' : formattedAmounts[Field.OUTPUT]}
                  disabled={disableTokenInputs}
                  onUserInput={handleTypeOutput}
                  label={<Trans>You receive</Trans>}
                  showMaxButton={false}
                  hideBalance={false}
                  fiatValue={showFiatValueOutput ? fiatValueOutput : undefined}
                  priceImpact={priceImpact}
                  currency={currencies[Field.OUTPUT] ?? null}
                  onCurrencySelect={handleOutputSelect}
                  otherCurrency={currencies[Field.INPUT]}
                  showCommonBases
                  id={InterfaceSectionName.CURRENCY_OUTPUT_PANEL}
                  loading={independentField === Field.INPUT && routeIsSyncing}
                  numericalInputSettings={{
                    // We disable numerical input here if the selected token has tax, since we cannot guarantee exact_outputs for FOT tokens
                    disabled: outputTokenHasTax,
                    // Focus the input currency panel if the user tries to type into the disabled output currency panel
                    onDisabledClick: () => inputCurrencyNumericalInputRef.current?.focus(),
                    disabledTooltipBody: <OutputTaxTooltipBody currencySymbol={currencies[Field.OUTPUT]?.symbol} />,
                  }}
                />
              </Trace>
              {recipient !== null && !showWrap ? (
                <>
                  <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                    <ArrowWrapper clickable={false}>
                      <ArrowDown size="16" color={theme.neutral2} />
                    </ArrowWrapper>
                    <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                      <Trans>- Remove recipient</Trans>
                    </LinkStyledButton>
                  </AutoRow>
                  <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
                </>
              ) : null}
            </OutputSwapSection>
          </div>
          {showDetailsDropdown && (
            <BestSwapDetailsDropdownContainer
              priceImpact={priceImpact}
              isLHSwap={isLHSwap}
              wrappedTrade={wrappedTrade}
              loading={loadingOptimalRate && liquidityHub.quoteLoading}
              allowedSlippage={allowedSlippage}
            />
          )}
          {showPriceImpactWarning && <PriceImpactWarning priceImpact={largerPriceImpact} />}
          <ButtonSwapContainer>
            {swapIsUnsupported ? (
              <ButtonPrimary $borderRadius="16px" disabled={true}>
                <ThemedText.DeprecatedMain mb="4px">
                  <Trans>Unsupported Asset</Trans>
                </ThemedText.DeprecatedMain>
              </ButtonPrimary>
            ) : switchingChain ? (
              <ButtonPrimary $borderRadius="16px" disabled={true}>
                <Trans>Connecting to {getChainInfo(switchingChain)?.label}</Trans>
              </ButtonPrimary>
            ) : !account ? (
              <TraceEvent
                events={[BrowserEvent.onClick]}
                name={InterfaceEventName.CONNECT_WALLET_BUTTON_CLICKED}
                properties={{ received_swap_quote: getIsValidSwapQuote(wrappedTrade, tradeState, swapInputError) }}
                element={InterfaceElementName.CONNECT_WALLET_BUTTON}
              >
                <ButtonLight onClick={toggleWalletDrawer} fontWeight={535} $borderRadius="16px">
                  <Trans>Connect Wallet</Trans>
                </ButtonLight>
              </TraceEvent>
            ) : chainId && chainId !== connectedChainId ? (
              <ButtonPrimary
                $borderRadius="16px"
                onClick={async () => {
                  try {
                    await switchChain(connector, chainId)
                  } catch (error) {
                    if (didUserReject(error)) {
                      // Ignore error, which keeps the user on the previous chain.
                    } else {
                      // TODO(WEB-3306): This UX could be improved to show an error state.
                      throw error
                    }
                  }
                }}
              >
                Connect to {getChainInfo(connectedChainId)?.label}
              </ButtonPrimary>
            ) : showWrap ? (
              <ButtonPrimary
                $borderRadius="16px"
                disabled={Boolean(wrapInputError)}
                onClick={handleOnWrap}
                fontWeight={535}
                data-testid="wrap-button"
              >
                {wrapInputError ? (
                  <WrapErrorText wrapInputError={wrapInputError} />
                ) : wrapType === WrapType.WRAP ? (
                  <Trans>Wrap</Trans>
                ) : wrapType === WrapType.UNWRAP ? (
                  <Trans>Unwrap</Trans>
                ) : null}
              </ButtonPrimary>
            ) : routeNotFound && userHasSpecifiedInputOutput && !routeIsLoading && !routeIsSyncing ? (
              <GrayCard style={{ textAlign: 'center' }}>
                <ThemedText.DeprecatedMain mb="4px">
                  <Trans>Insufficient liquidity for this trade.</Trans>
                </ThemedText.DeprecatedMain>
              </GrayCard>
            ) : (
              <TraceEvent
                events={[BrowserEvent.onClick]}
                name={SharedEventName.ELEMENT_CLICKED}
                element={InterfaceElementName.SWAP_BUTTON}
              >
                <ButtonError
                  onClick={() => {
                    showPriceImpactWarning ? setShowPriceImpactModal(true) : handleContinueToReview()
                  }}
                  id="swap-button"
                  data-testid="swap-button"
                  disabled={isSwapButtonDisabled}
                  error={!swapInputError && priceImpactSeverity > 2 && allowance.state === AllowanceState.ALLOWED}
                >
                  <Text fontSize={20}>
                    {swapInputError ? (
                      swapInputError
                    ) : routeIsSyncing || routeIsLoading ? (
                      <Trans>Swap</Trans>
                    ) : priceImpactSeverity > 2 ? (
                      <Trans>Swap Anyway</Trans>
                    ) : (
                      <Trans>Swap</Trans>
                    )}
                  </Text>
                </ButtonError>
              </TraceEvent>
            )}
          </ButtonSwapContainer>
          {isLHSwap && <LiquidityHub.PoweredByOrbs style={{ marginBottom: 30 }} />}
        </AutoColumn>
      </SwapWrapper>
      <LiquidityHub.SwapModal
        fromTokenUsd={fiatValueInput.data}
        toTokenUsd={fiatValueOutput.data}
        priceImpact={priceImpact}
        onCurrencySelection={onCurrencySelection}
        onUserInput={onUserInput}
      />
    </SwapContainer>
  )

  return <>{swapElement}</>
}

const BestSwapDetailsDropdownContainer = ({
  wrappedTrade,
  loading,
  isLHSwap,
  allowedSlippage,
  priceImpact,
}: {
  wrappedTrade?: IHasTradeProperties
  loading: boolean
  allowedSlippage: Percent
  isLHSwap: boolean
  priceImpact?: Percent
}) => {
  ;``
  const [showDetails, setShowDetails] = useState(false)

  if (isLHSwap) {
    return (
      <LiquidityHub.SwapDetails
        tradePriceComponent={wrappedTrade && <TradePrice price={wrappedTrade?.executionPrice} />}
        priceImpact={priceImpact}
        showDetails={showDetails}
        setShowDetails={setShowDetails}
      />
    )
  }

  return (
    <SwapDetailsDropdown
      trade={wrappedTrade}
      //TODO: figure out whether we need syncing since the other routes would probably take priority
      syncing={false}
      loading={loading}
      allowedSlippage={allowedSlippage}
      showDetails={showDetails}
      setShowDetails={setShowDetails}
    />
  )
}

interface Props {
  className?: string
  prefilledState?: Partial<SwapState>
  chainId?: ChainId
  onCurrencyChange?: (selected: Pick<SwapState, Field.INPUT | Field.OUTPUT>) => void
  disableTokenInputs?: boolean
}

export const BestSwapInnerContent = (props: Props) => {
  return (
    <LiquidityHub.Provider>
      <BestSwapContent {...props} />
    </LiquidityHub.Provider>
  )
}
