import type { TransactionResponse } from '@ethersproject/providers'
import { Trans } from '@lingui/macro'
import { CurrencyAmount, Percent, Token } from '@uniswap/sdk-core'
import { Pair } from '@uniswap/v2-sdk'
import { useWeb3React } from '@web3-react/core'
import { sendEvent } from 'components/analytics'
import { FixedHeightRow } from 'components/PositionCard'
import TransactionConfirmationModal, { ConfirmationModalContent } from 'components/TransactionConfirmationModal'
import { ZERO_PERCENT } from 'constants/misc'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { useVersionedMasterchef } from 'hooks/useContract'
import { useNetworkSupportsV2 } from 'hooks/useNetworkSupportsV2'
import JSBI from 'jsbi'
import { ConfirmAddFarmModalBottom } from 'pages/Farm/FarmDetails/ConfirmAddModalBottom'
import { transparentize } from 'polished'
import { useCallback, useMemo, useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { Text } from 'rebass'
import { Farm } from 'state/farm/types'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled from 'styled-components'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { calculateSlippageAmount } from 'utils/calculateSlippageAmount'

import { LightCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import Row, { AutoRow, RowBetween, RowFixed, RowFlat } from '../../components/Row'
import Slider from '../../components/Slider'
import { useColor } from '../../hooks/useColor'
import useDebouncedChangeHandler from '../../hooks/useDebouncedChangeHandler'
import { useTotalSupply } from '../../hooks/useTotalSupply'
import { MaxButton, Wrapper } from '../../pages/Pool/styled'
import { Field } from '../../state/burn/actions'
import { useTokenBalance } from '../../state/connection/hooks'
import { useDerivedFarmLiquidityInfo } from '../../state/farm/hooks'
import { colors } from '../../theme/colors'
import { currencyId } from '../../utils/currencyId'
import { unwrappedToken } from '../../utils/unwrappedToken'
import { ButtonEmpty, ButtonError, ButtonPrimary } from '../Button'
import DoubleCurrencyLogo from '../DoubleLogo'
import { CardNoise } from '../earn/styled'
import CurrencyLogo from '../Logo/CurrencyLogo'
import { Dots } from '../swap/styled'

const StyledFarmCard = styled(LightCard)<{ bgColor: any }>`
  border: none;
  background: ${({ theme, bgColor }) =>
    `radial-gradient(91.85% 100% at 1.84% 0%, ${transparentize(0.8, bgColor)} 0%, ${theme.surface2} 100%) `};
  position: relative;
  overflow: hidden;
`

interface FarmCardProps {
  pair: Pair
  showUnwrapped?: boolean
  border?: string
  stakedBalance?: CurrencyAmount<Token> // optional balance to indicate that liquidity is deposited in mining pool
  rewardBalance?: CurrencyAmount<Token> // optional balance to indicate the rewards balance due
  matchingFarm: Farm
}

export enum FarmAction {
  Add = 1,
  Remove = 2,
  Claim = 3,
}

function actionToDisplayName(action: FarmAction | undefined) {
  if (action === FarmAction.Add) return 'Add'
  if (action === FarmAction.Remove) return 'Remove'
  if (action === FarmAction.Claim) return 'Claim'
  return 'Unknown'
}

function actionToTransactionType(action: FarmAction) {
  if (action === FarmAction.Add) return TransactionType.DEPOSIT_LIQUIDITY_STAKING
  if (action === FarmAction.Remove) return TransactionType.WITHDRAW_LIQUIDITY_STAKING

  return TransactionType.CLAIM
}

export default function FullFarmCard({ pair, border, stakedBalance, rewardBalance, matchingFarm }: FarmCardProps) {
  const { account, chainId, provider } = useWeb3React()

  const currency0 = unwrappedToken(pair.token0)
  const currency1 = unwrappedToken(pair.token1)

  const [showMore, setShowMore] = useState(true)
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm
  const [txHash, setTxHash] = useState<string>('')
  const [farmActionState, setFarmActionState] = useState<FarmAction>(FarmAction.Add)
  const [independentField, setIndependentField] = useState<Field>()
  const [typedValue, setTypedValue] = useState<string>('0')
  const [isAddSelected, setIsAddSelected] = useState(true)

  const handleToggle = (isAdd: boolean) => {
    setIsAddSelected(isAdd)
    setFarmActionState(isAdd ? FarmAction.Add : FarmAction.Remove)
  }
  type ToggleButtonProps = {
    title: string
    isSelected: boolean
    isAdd: boolean
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      setIndependentField(field)
      setTypedValue(typedValue)
    },
    [setIndependentField, setTypedValue]
  )

  const ToggleButton: React.FC<ToggleButtonProps> = ({ title, isSelected, isAdd }) => {
    return (
      <button
        style={{
          backgroundColor: isSelected ? colors.accent1_dark : 'grey',
          color: isSelected ? 'white' : 'black',
          height: '40px',
          fontWeight: isSelected ? 'bold' : 'normal',
          border: 'none',
          borderRadius: title == 'Add' ? '8px 0 0 8px' : '0 8px 8px 0',
          margin: '0',
          cursor: 'pointer',
        }}
        onClick={() => {
          onUserInput(Field.LIQUIDITY_PERCENT, '0')
          handleToggle(isAdd)
        }}
      >
        {title}
      </button>
    )
  }

  const { parsedAmounts, error } = useDerivedFarmLiquidityInfo(
    farmActionState === FarmAction.Remove,
    independentField === Field.LIQUIDITY_PERCENT,
    typedValue,
    matchingFarm.pid.toString(),
    matchingFarm.version.toString(),
    pair
  )

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setTxHash('')
    if (farmActionState === FarmAction.Claim) {
      handleToggle(true)
      onUserInput(Field.LIQUIDITY_PERCENT, '0')
    }
  }, [farmActionState, onUserInput])

  const userDefaultPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  // Note: for new version we only support masterchef v3 for older versions they should use the legacy site
  // However for simplicity we will pass whatever is in the metadata to simplfy expansion in the future
  const masterChefContract = useVersionedMasterchef(matchingFarm.version)
  const { descriptiveAction, formattedTokenName, formattedPendingText } = useMemo(() => {
    const formattedTokenName = `${currency0?.symbol} / ${currency1?.symbol} LP`

    const descriptiveAction = actionToDisplayName(farmActionState)

    const formattedPendingText = `${descriptiveAction} ${parsedAmounts[Field.LIQUIDITY]?.toSignificant(
      6
    )} ${formattedTokenName} ${farmActionState === FarmAction.Claim ? ' Rewards' : ''}`
    return {
      descriptiveAction,
      formattedTokenName,
      formattedPendingText,
    }
  }, [currency0, currency1, parsedAmounts, farmActionState])

  const pendingText = <Trans>{formattedPendingText}</Trans>

  const addTransaction = useTransactionAdder()
  const networkSupportsV2 = useNetworkSupportsV2()

  const [liquidtyTokenApproval, liquidityTokenApprovalCallback] = useApproveCallback(
    parsedAmounts[Field.LIQUIDITY],
    masterChefContract?.address
  )

  const isValid =
    chainId && provider && masterChefContract && networkSupportsV2 && parsedAmounts[Field.LIQUIDITY] && !error

  const formattedAmounts = {
    [Field.LIQUIDITY_PERCENT]: parsedAmounts[Field.LIQUIDITY_PERCENT].equalTo('0')
      ? '0'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].lessThan(new Percent('1', '100'))
      ? '<1'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0),
    [Field.LIQUIDITY]:
      independentField === Field.LIQUIDITY ? typedValue : parsedAmounts[Field.LIQUIDITY]?.toSignificant(6) ?? '',
  }

  const atMaxAmount = parsedAmounts[Field.LIQUIDITY_PERCENT]?.equalTo(new Percent('1'))

  const onLiquidityInput = useCallback(
    (typedValue: string): void => onUserInput(Field.LIQUIDITY, typedValue),
    [onUserInput]
  )

  const liquidityPercentChangeCallback = useCallback(
    (value: number) => {
      onUserInput(Field.LIQUIDITY_PERCENT, value.toString())
    },
    [onUserInput]
  )

  const [innerLiquidityPercentage, setInnerLiquidityPercentage] = useDebouncedChangeHandler(
    Number.parseInt(parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0)),
    liquidityPercentChangeCallback
  )

  //Should we use callback here?
  async function onExecute() {
    if (
      !chainId ||
      !provider ||
      !account ||
      !masterChefContract ||
      !networkSupportsV2 ||
      !parsedAmounts[Field.LIQUIDITY] ||
      !farmActionState
    )
      return

    const methodIdentifier =
      farmActionState != FarmAction.Add ? 'withdraw(uint256,uint256)' : 'deposit(uint256,uint256)'

    const amountDeltaRaw =
      farmActionState != FarmAction.Claim ? calculateSlippageAmount(parsedAmounts[Field.LIQUIDITY], ZERO_PERCENT)[0] : 0
    const estimate = masterChefContract.estimateGas[methodIdentifier]
    const method = masterChefContract[methodIdentifier]
    const args = [matchingFarm.pid, amountDeltaRaw.toString()]
    const value = null

    setAttemptingTxn(true)
    await estimate(...args, value ? { value } : {})
      .then((estimatedGasLimit) =>
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit),
        }).then((response: TransactionResponse) => {
          setAttemptingTxn(false)

          addTransaction(response, {
            type: actionToTransactionType(farmActionState),
            token0Address: currencyId(currency0),
            token1Address: currencyId(currency1),
            recipient: account,
          })

          setTxHash(response.hash)

          sendEvent({
            category: 'Liquidity',
            action: `${descriptiveAction} Farm`,
            label: formattedTokenName,
          })
        })
      )
      .catch((error) => {
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

  const modalHeader = () => {
    return (
      <AutoColumn gap="20px">
        <LightCard mt="20px" $borderRadius="20px">
          <RowFlat>
            <Text fontSize="48px" fontWeight={535} lineHeight="42px" marginRight={10}>
              {formattedTokenName}
            </Text>
            <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={30} />
          </RowFlat>
        </LightCard>
      </AutoColumn>
    )
  }

  const modalBottom = () => {
    return (
      <>
        <ConfirmAddFarmModalBottom
          formattedTokenName={formattedTokenName}
          lpToken={pair.liquidityToken}
          depositedAmount={stakedBalance}
          farmAction={farmActionState}
          amountDelta={parsedAmounts[Field.LIQUIDITY]}
          onExecute={onExecute}
        />
      </>
    )
  }

  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  // if staked balance balance provided, add to standard liquidity amount
  const userPoolBalance = stakedBalance ? userDefaultPoolBalance?.add(stakedBalance) : userDefaultPoolBalance

  const poolTokenPercentage =
    !!userPoolBalance &&
    !!totalPoolTokens &&
    JSBI.greaterThanOrEqual(totalPoolTokens.quotient, userPoolBalance.quotient)
      ? new Percent(userPoolBalance.quotient, totalPoolTokens.quotient)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.quotient, userPoolBalance.quotient)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
        ]
      : [undefined, undefined]

  const backgroundColor = useColor(pair?.token0)

  return (
    <StyledFarmCard border={border} bgColor={backgroundColor}>
      <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={attemptingTxn}
        hash={txHash}
        reviewContent={() => (
          <ConfirmationModalContent
            title={<Trans>Performing action ({descriptiveAction}) on a farm</Trans>}
            onDismiss={handleDismissConfirmation}
            topContent={modalHeader}
            bottomContent={modalBottom}
          />
        )}
        pendingText={pendingText}
        currencyToAdd={pair?.liquidityToken}
      />
      <CardNoise />
      <AutoColumn gap="md">
        <FixedHeightRow>
          <AutoRow gap="8px" style={{ marginLeft: '8px' }}>
            <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={20} />
            <Text fontWeight={535} fontSize={20}>
              {!currency0 || !currency1 ? (
                <Dots>
                  <Trans>Loading</Trans>
                </Dots>
              ) : (
                `${currency0.symbol}/${currency1.symbol}`
              )}
            </Text>
          </AutoRow>
          <RowFixed gap="8px" style={{ marginRight: '4px' }}>
            <ButtonEmpty padding="6px 8px" $borderRadius="12px" width="100%" onClick={() => setShowMore(!showMore)}>
              {showMore ? (
                <>
                  <Trans>Manage</Trans>
                  <ChevronUp size="20" style={{ marginLeft: '8px', height: '20px', minWidth: '20px' }} />
                </>
              ) : (
                <>
                  <Trans>Manage</Trans>
                  <ChevronDown size="20" style={{ marginLeft: '8px', height: '20px', minWidth: '20px' }} />
                </>
              )}
            </ButtonEmpty>
          </RowFixed>
        </FixedHeightRow>

        {showMore && (
          <AutoColumn gap="sm">
            <FixedHeightRow>
              <Text fontSize={16} fontWeight={535}>
                <Trans>Your total pool tokens:</Trans>
              </Text>
              <Text fontSize={16} fontWeight={535}>
                {userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
              </Text>
            </FixedHeightRow>
            {stakedBalance && (
              <FixedHeightRow>
                <Text fontSize={16} fontWeight={535}>
                  <Trans>Pool tokens in rewards pool:</Trans>
                </Text>
                <Text fontSize={16} fontWeight={535}>
                  {stakedBalance.toSignificant(4)}
                </Text>
              </FixedHeightRow>
            )}
            {/* TODO re-enable this when we retreive it from the proper place */}
            {/* {rewardBalance && (
              <FixedHeightRow>
                <Text fontSize={16} fontWeight={535}>
                  <Trans>Claimable {rewardBalance.currency.symbol} Rewards:</Trans>
                </Text>
                <Text fontSize={16} fontWeight={535}>
                  {rewardBalance.toSignificant(4)}
                </Text>
              </FixedHeightRow>
            )} */}
            <FixedHeightRow>
              <RowFixed>
                <Text fontSize={16} fontWeight={535}>
                  <Trans>Pooled {currency0.symbol}:</Trans>
                </Text>
              </RowFixed>
              {token0Deposited ? (
                <RowFixed>
                  <Text fontSize={16} fontWeight={535} marginLeft="6px">
                    {token0Deposited?.toSignificant(6)}
                  </Text>
                  <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency0} />
                </RowFixed>
              ) : (
                '-'
              )}
            </FixedHeightRow>

            <FixedHeightRow>
              <RowFixed>
                <Text fontSize={16} fontWeight={535}>
                  <Trans>Pooled {currency1.symbol}:</Trans>
                </Text>
              </RowFixed>
              {token1Deposited ? (
                <RowFixed>
                  <Text fontSize={16} fontWeight={535} marginLeft="6px">
                    {token1Deposited?.toSignificant(6)}
                  </Text>
                  <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency1} />
                </RowFixed>
              ) : (
                '-'
              )}
            </FixedHeightRow>

            <FixedHeightRow>
              <Text fontSize={16} fontWeight={535}>
                <Trans>Your pool share:</Trans>
              </Text>
              <Text fontSize={16} fontWeight={535}>
                {poolTokenPercentage ? (
                  <Trans>
                    {poolTokenPercentage.toFixed(2) === '0.00' ? '<0.01' : poolTokenPercentage.toFixed(2)} %
                  </Trans>
                ) : (
                  '-'
                )}
              </Text>
            </FixedHeightRow>
            <Wrapper>
              <AutoColumn gap="md">
                <LightCard>
                  <RowBetween marginTop="10px">
                    {/* Toggle Button */}

                    <div
                      id="toggleButton"
                      style={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                        width: '30%',
                      }}
                    >
                      <ToggleButton title="Add" isAdd={true} isSelected={isAddSelected} />
                      <ToggleButton title="Remove" isAdd={false} isSelected={!isAddSelected} />
                    </div>
                    {/* End Toggle Button */}
                    {/* Claim Button */}
                    <ButtonPrimary
                      onClick={() => {
                        onUserInput(Field.LIQUIDITY_PERCENT, '100')
                        setFarmActionState(FarmAction.Claim)
                        setShowConfirm(true)
                      }}
                      padding="8px"
                      $borderRadius="8px"
                      width="32%"
                    >
                      <Trans>Claim</Trans>
                    </ButtonPrimary>
                    {/* End Claim Button */}
                  </RowBetween>
                  {/* TODO: Migrate the logic to the new buttons */}
                  {/* <RowBetween marginTop="10px" style={{ display: 'none' }}>
                    <ButtonPrimary
                      onClick={() => {
                        setFarmActionState(FarmAction.Remove)
                        setShowConfirm(true)
                      }}
                      disabled={!isValid}
                      padding="8px"
                      $borderRadius="8px"
                      width="32%"
                    >
                      <Trans>Remove</Trans>
                    </ButtonPrimary>
                  </RowBetween> */}
                  <AutoColumn gap="20px">
                    <Row style={{ alignItems: 'flex-end' }}>
                      <Text fontSize={72} fontWeight={535}>
                        {formattedAmounts[Field.LIQUIDITY_PERCENT]}%
                      </Text>
                    </Row>
                    <CurrencyInputPanel
                      value={formattedAmounts[Field.LIQUIDITY]}
                      onUserInput={onLiquidityInput}
                      onMax={() => {
                        onUserInput(Field.LIQUIDITY_PERCENT, '100')
                      }}
                      showMaxButton={!atMaxAmount}
                      currency={pair?.liquidityToken}
                      pair={pair}
                      overriddenBalance={farmActionState === FarmAction.Remove ? stakedBalance : userDefaultPoolBalance}
                      id="farm-liquidity-amount"
                    />
                    <Slider value={innerLiquidityPercentage} onChange={setInnerLiquidityPercentage} />
                    <RowBetween>
                      <MaxButton onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '25')} width="20%">
                        25%
                      </MaxButton>
                      <MaxButton onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '50')} width="20%">
                        50%
                      </MaxButton>
                      <MaxButton onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '75')} width="20%">
                        75%
                      </MaxButton>
                      <MaxButton onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')} width="20%">
                        Max
                      </MaxButton>
                    </RowBetween>
                  </AutoColumn>
                  <RowBetween marginTop="15px">
                    {(liquidtyTokenApproval === ApprovalState.NOT_APPROVED && isAddSelected) ||
                    (liquidtyTokenApproval === ApprovalState.PENDING && isAddSelected) ? (
                      <ButtonPrimary
                        onClick={liquidityTokenApprovalCallback}
                        width="99%"
                        style={{ marginLeft: 'auto', marginRight: 'auto' }}
                        disabled={liquidtyTokenApproval === ApprovalState.PENDING}
                      >
                        {liquidtyTokenApproval === ApprovalState.PENDING ? (
                          <Dots>
                            <Trans>Approving {formattedTokenName}</Trans>
                          </Dots>
                        ) : (
                          <Trans>Approve {formattedTokenName}</Trans>
                        )}
                      </ButtonPrimary>
                    ) : (
                      <ButtonError
                        onClick={() => {
                          setShowConfirm(true)
                        }}
                        disabled={!isValid}
                        padding="8px"
                        // $borderRadius="8px"
                        width="99%"
                        style={{ marginLeft: 'auto', marginRight: 'auto' }}
                      >
                        <Trans>Confirm</Trans>
                      </ButtonError>
                    )}
                  </RowBetween>
                </LightCard>
              </AutoColumn>
            </Wrapper>
            {/* {userDefaultPoolBalance && JSBI.greaterThan(userDefaultPoolBalance.quotient, BIG_INT_ZERO) && ( */}

            {/* )} */}
          </AutoColumn>
        )}
      </AutoColumn>
    </StyledFarmCard>
  )
}
