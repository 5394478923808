import { useWeb3React } from '@web3-react/core'
import FarmListJSON from 'constants/farms/default_spooky_farms.json'
import { CACHE_REFRESH_MS } from 'constants/misc'
import { useTokenPriceCache } from 'hooks/useRPCTokenPriceFetcher'
// import usePriceFetcher from 'hooks/v2/usePriceFetcher'
import { useEffect, useState } from 'react'
import { useFetchFarms } from 'state/farm/hooks'
import { useAppDispatch } from 'state/hooks'

import { useCacheActionHandlers } from './hooks'

//TODO: fix this example we should be updating the cache
// on a time basis every x minutes.  Additionally, we should update the cache when
// the chainId changes

export default function Updater(): null {
  const { chainId } = useWeb3React()
  const dispatch = useAppDispatch()

  const { onRPCTokenPricesChange } = useCacheActionHandlers()
  const [refreshCounter, setRefreshCounter] = useState(0)
  const { isLoading, isError, fetchedFarmData } = useFetchFarms(FarmListJSON.farms)
  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshCounter((prevCounter) => prevCounter + 1)
    }, CACHE_REFRESH_MS)

    return () => clearInterval(interval)
  }, [])

  //The refresh timer is offloaded into useTokenPriceCache() see refreshCounter
  const tokenPrices = useTokenPriceCache(refreshCounter)
  useEffect(() => {
    onRPCTokenPricesChange(tokenPrices)
  }, [dispatch, chainId, onRPCTokenPricesChange, tokenPrices])

  // usePriceFetcher(fetchedFarmData, refreshCounter)

  return null
}
