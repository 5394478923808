import { Trans } from '@lingui/macro'
import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import { Pool } from '@uniswap/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import { usePendingActivity } from 'components/AccountDrawer/MiniPortfolio/Activity/hooks'
import Badge from 'components/Badge'
import { ButtonLight, ButtonPrimary, ButtonSecondary } from 'components/Button'
import { LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import { FarmAction } from 'components/FarmCard'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { RowBetween, RowFixed } from 'components/Row'
import { useFarmV3Helper } from 'hooks/useContract'
import { useV3PositionsFromFarm, useV3PositionsFromTokenIds } from 'hooks/useV3Positions'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { useTokenFromActiveNetwork } from 'lib/hooks/useCurrency'
import { Box } from 'nft/components/Box'
import { Table } from 'nft/components/explore/Table'
import { themeVars } from 'nft/css/sprinkles.css'
import React, { useEffect, useRef, useState } from 'react'
import { CheckCircle } from 'react-feather'
import { Row } from 'react-table'
import styled from 'styled-components'
import { ThemedText } from 'theme'

import { UserPosition } from '../hooks/fetchFarms'
import { PositionMetadata } from '../hooks/misc'
import { Incentive } from './details'

type Props = {
  row: Row<{
    key: string
    nft: string
    tokenId: string
    positionValue: string
    pendingRewards: number
    staked: boolean
  }>
}
export function PositionsTable({
  positions,
  pool,
  setModal,
  setFarmActionState,
  setToken,
  incentive,
}: {
  positions: UserPosition[]
  pool: Pool
  setModal: (modal: any) => void
  setFarmActionState: (action: FarmAction) => void
  setToken: (token: any) => void
  incentive: Incentive
}) {
  const { account } = useWeb3React()
  const tokenIds = positions.map((position) => position.tokenId)
  const { loading, positions: userPositions } = useV3PositionsFromTokenIds(tokenIds)
  const { positions: filteredPositions } = useV3PositionsFromFarm(account, pool)
  const rewardsRef = useRef<any>({})
  const [rewardsUpdateTrigger, setRewardsUpdateTrigger] = useState(false)
  const { hasPendingActivity } = usePendingActivity()

  const updateDataWithReward = (tokenId: string, newReward: string) => {
    rewardsRef.current[tokenId] = newReward
    setRewardsUpdateTrigger((prev) => !prev) // This will trigger a state update
  }

  const data = React.useMemo(() => {
    return filteredPositions?.map((position) => ({
      nft: position.tokenId,
      tokenId: position.tokenId.toString(),
      positionValue: '',
      pendingRewards: 0,
    }))
  }, [filteredPositions, rewardsUpdateTrigger])

  const clearPoolData = (original: any) => {
    setToken(original.tokenId)
    setModal(true)
  }

  function handleWithdraw(original: any): void {
    setFarmActionState(FarmAction.Remove)
    clearPoolData(original)
  }

  function handleDepositAndStake(original: any): void {
    setFarmActionState(FarmAction.Add)
    clearPoolData(original)
  }

  function handleClaim(original: any): void {
    setFarmActionState(FarmAction.Claim)
    clearPoolData(original)
  }

  const columns = React.useMemo(() => {
    return [
      {
        accessor: 'nft',
        title: 'NFT',
        dataIndex: 'nft',
        key: 'nft',
        Cell: ({ row }: Props) => (
          <Box
            paddingY="6"
            paddingX="6"
            display="flex"
            alignItems="center"
            opacity={row.original.staked === true ? '0.5' : '1'}
          >
            {' '}
            <PositionMetadata height={90} position={row.original} />
          </Box>
        ),
        Header: () => <div>NFT</div>,
      },
      {
        accessor: 'pendingRewards',
        title: 'Pending Rewards',
        dataIndex: 'pendingRewards',
        key: 'pendingRewards',
        Cell: ({ row }: { row: Row }) => (
          <RewardCell row={row} incentive={incentive} updateReward={updateDataWithReward} />
        ),
        Header: () => <div>Pending Rewards</div>,
      },
      {
        accessor: 'staked',
        title: 'Staked',
        dataIndex: 'staked',
        key: 'staked',
        Cell: ({ row }: Props) => (
          <div>
            {row.original.staked === true ? (
              <LabelText color={themeVars.colors.accent1}>
                <Badge style={{ gap: '8px' }}>
                  <BadgeText style={{ color: 'green', display: 'flex', gap: '4px' }}>
                    <Trans>Staked</Trans>
                    <CheckCircle color="green" width="25px" />
                  </BadgeText>
                </Badge>
              </LabelText>
            ) : (
              <LabelText color={themeVars.colors.neutral1}>
                <Badge style={{ gap: '8px' }}>
                  <BadgeText>
                    <Trans>Not Staked</Trans>
                  </BadgeText>
                </Badge>
              </LabelText>
            )}
          </div>
        ),
        Header: () => <div>Staked</div>,
      },
      {
        accessor: 'manageLiquidity',
        title: 'Manage Liquidity',
        Cell: ({ row }: Props) => {
          const isRewardZeroOrUndefined =
            rewardsRef.current?.[row.original.tokenId] === '0' || rewardsRef.current == undefined
          return (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {row.original.staked ? (
                <AutoColumn
                  style={{
                    width: '50%',
                    rowGap: '10px',
                  }}
                >
                  <ButtonSecondary
                    padding="6px 8px"
                    width="100%"
                    justifyContent="end"
                    fontSize="14px !important"
                    onClick={() => handleWithdraw(row.original)}
                    disabled={hasPendingActivity}
                  >
                    Withdraw
                  </ButtonSecondary>
                  <ButtonLight
                    padding="6px 8px"
                    width="100%"
                    justifyContent="end"
                    fontSize="14px !important"
                    onClick={() => handleClaim(row.original)}
                    disabled={hasPendingActivity || isRewardZeroOrUndefined}
                  >
                    Claim
                  </ButtonLight>
                </AutoColumn>
              ) : isRewardZeroOrUndefined ? (
                <ButtonPrimary
                  padding="6px 8px !important"
                  width="50%"
                  fontSize="14px !important"
                  onClick={() => handleDepositAndStake(row.original)}
                  disabled={hasPendingActivity}
                >
                  Deposit
                </ButtonPrimary>
              ) : (
                <AutoColumn
                  style={{
                    width: '50%',
                    rowGap: '10px',
                  }}
                >
                  <ButtonPrimary
                    padding="6px 8px !important"
                    width="100%"
                    justifyContent="end"
                    fontSize="14px !important"
                    onClick={() => handleDepositAndStake(row.original)}
                    disabled={hasPendingActivity}
                  >
                    Deposit
                  </ButtonPrimary>
                  <ButtonLight
                    padding="6px 8px"
                    width="100%"
                    justifyContent="end"
                    fontSize="14px !important"
                    onClick={() => handleClaim(row.original)}
                    disabled={hasPendingActivity || isRewardZeroOrUndefined}
                  >
                    Claim
                  </ButtonLight>
                </AutoColumn>
              )}
            </div>
          )
        },
        Header: () => <div>Manage Liquidity</div>,
      },
    ]
  }, [positions, rewardsRef.current, hasPendingActivity, rewardsUpdateTrigger])

  return (
    <Table
      columns={columns}
      data={data}
      smallHiddenColumns={['']}
      mediumHiddenColumns={['']}
      largeHiddenColumns={['symbol']}
    />
  )
}
const RewardCell = ({
  row,
  incentive,
  updateReward,
}: {
  row: Row<any>
  incentive: Incentive
  updateReward: (tokenId: string, newReward: string) => void
}) => {
  const { chainId } = useWeb3React()
  const [reward, setReward] = useState('0')
  const rewardTokenQuery = useTokenFromActiveNetwork(incentive.rewardToken) as Token

  const tokenId = row.original.tokenId // Assuming `tokenId` is directly available in row values
  const poolStruct = [incentive.rewardToken, incentive.pool, incentive.startTime, incentive.endTime, incentive.refundee]
  const UniV3StakerContract = useFarmV3Helper()

  // Assuming `UniV3StakerContract` is correctly instantiated elsewhere and available here
  const pendingRewardResult = useSingleCallResult(UniV3StakerContract, 'getRewardInfo', [poolStruct, tokenId])

  useEffect(() => {
    let rewardAmount = reward
    if (pendingRewardResult.result && pendingRewardResult.valid && pendingRewardResult.result.length > 0) {
      rewardAmount = pendingRewardResult.result.reward // Assuming the first item in the result array is the reward amount
      setReward(rewardAmount.toString()) // Convert the reward amount to string if needed
    } else if (pendingRewardResult.error) {
      console.error('Error fetching pending reward:', pendingRewardResult.error, pendingRewardResult)
    }
    updateReward(tokenId, rewardAmount)
  }, [pendingRewardResult, reward, tokenId, updateReward])

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <LightCard padding="6px 8px" maxWidth="60%">
        <AutoColumn gap="md">
          <RowBetween>
            {rewardTokenQuery && (
              <CurrencyLogo currency={rewardTokenQuery} size="20px" style={{ marginRight: '0.5rem' }} />
            )}
            <RowFixed>
              <ThemedText.DeprecatedMain>
                {CurrencyAmount.fromRawAmount(rewardTokenQuery, reward).greaterThan(0) &&
                CurrencyAmount.fromRawAmount(rewardTokenQuery, reward).toFixed(3) === '0.000'
                  ? '<0.001'
                  : CurrencyAmount.fromRawAmount(rewardTokenQuery, reward).toFixed(
                      CurrencyAmount.fromRawAmount(rewardTokenQuery, reward).greaterThan(1000) ? 2 : 4
                    )}
              </ThemedText.DeprecatedMain>
            </RowFixed>
          </RowBetween>
        </AutoColumn>
      </LightCard>
    </div>
  )
}

const SmallButtonLight = styled(ButtonLight)`
  font-size: 16px;
  padding: 10px 16px;
  border-radius: 12px;
`

const LabelText = styled.div<{ color: string }>`
  align-items: center;
  color: ${({ color }) => color} !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`
const BadgeWrapper = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
`

const BadgeText = styled.div`
  font-weight: 535;
  font-size: 14px;
  color: ${({ theme }) => theme.neutral2};
`
